import { PlantList } from '@/types.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToastNotifications } from '@/contexts/hooks/useToastNotifications.ts'
import { archivePlantList, unArchivePlantList } from '@/api/plant-list.ts'
import { Box } from '@mui/material'
import { TextPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import CircularProgress from '@mui/material/CircularProgress'
import { Archive } from 'lucide-react'

type PlantListDetailContentProps = { plantList: PlantList }

export const ArchivePlantList = ({
  plantList,
}: PlantListDetailContentProps) => {
  if (!plantList) {
    return null
  }

  const plantListId = plantList.id
  const queryClient = useQueryClient()
  const { addToastNotification } = useToastNotifications()

  const InvalidatePlantListQuery = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['plant-list', plantListId],
    })
  }

  const ArchivePlantListSuccess = () => {
    addToastNotification({
      severity: 'success',
      title: 'Success: Plant List Archived',
      message: 'Plant list has been successfully archived',
    })
  }

  const ArchivePlantListError = (error: any) => {
    addToastNotification({
      severity: 'error',
      title: 'Error: Archive Plant List',
      message:
        error?.message || 'An error occurred while archiving the plant list',
    })
  }

  const UnArchivePlantListSuccess = () => {
    addToastNotification({
      severity: 'success',
      title: 'Success: Plant List Unarchived',
      message: 'Plant list has been successfully unarchived',
    })
  }

  const UnArchivePlantListError = (error: any) => {
    addToastNotification({
      severity: 'error',
      title: 'Error: Unarchive Plant List',
      message:
        error?.message || 'An error occurred while unarchiving the plant list',
    })
  }

  const archivePlantListMutation = useMutation({
    mutationFn: archivePlantList,
    onSuccess: async () => {
      ArchivePlantListSuccess()
      await InvalidatePlantListQuery()
    },
    onError: (error) => {
      ArchivePlantListError(error)
    },
  })

  const unArchivePlantListMutation = useMutation({
    mutationFn: unArchivePlantList,
    onSuccess: async () => {
      UnArchivePlantListSuccess()
      await InvalidatePlantListQuery()
    },
    onError: (error) => {
      UnArchivePlantListError(error)
    },
  })

  const triggerArchivePlantList = async () => {
    plantList?.deleted_at
      ? await unArchivePlantListMutation.mutateAsync(plantListId)
      : await archivePlantListMutation.mutateAsync(plantListId)
  }

  return (
    <Box display="flex">
      <TextPrimaryButton
        variant="outlined"
        color="primary"
        onClick={triggerArchivePlantList}
        startIcon={
          archivePlantListMutation.isPending ? (
            <CircularProgress size={16} />
          ) : (
            <Archive size={16} />
          )
        }
      >
        {plantList?.deleted_at ? 'Unarchive List' : 'Archive List'}
      </TextPrimaryButton>
    </Box>
  )
}
