import { AIProcessingData, PlantList } from '@/types.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useQuery } from '@tanstack/react-query'
import { getFileProcessingForPlantLists } from '@/api/ai-processed-plant-list-file.ts'
import { useMemo } from 'react'
import { Box } from '@mui/material'
import PlantListDetailTable from '@/components/plant-list-details/plant-list-table.tsx'

type PlantListTableContainerProps = {
  plantList: PlantList
  selectedFiles: string[]
  searchFilteredPlants: Set<string>
  selectedPlants: Set<string>
  setSelectedPlants: (plants: Set<string>) => void
  setTargetFileId: (fileId: string) => void
}

export function PlantListTableContainer({
  plantList,
  selectedFiles,
  searchFilteredPlants,
  selectedPlants,
  setSelectedPlants,
  setTargetFileId,
}: PlantListTableContainerProps) {
  const { selectedOrganization } = useOrganization()
  const { data: fileProcessingData, refetch: refetchFileProcessingData } =
    useQuery({
      queryKey: ['file-processing-data', plantList.id] as const,
      queryFn: ({ queryKey }) => {
        const [_, id] = queryKey
        return getFileProcessingForPlantLists(selectedOrganization?.id, [
          id as string,
        ])
      },
    })
  const memoizedFileProcessingData = useMemo(() => {
    return fileProcessingData as AIProcessingData[]
  }, [fileProcessingData])

  return (
    <Box
      mt={4}
      mx={0}
      flex={1}
      sx={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <PlantListDetailTable
        plantList={plantList}
        selectedFiles={selectedFiles}
        searchFilteredPlants={searchFilteredPlants}
        fileProcessingData={memoizedFileProcessingData}
        refetchFileProcessingData={refetchFileProcessingData}
        selectedPlants={selectedPlants}
        setSelectedPlants={setSelectedPlants}
        setTargetFileId={setTargetFileId}
      />
    </Box>
  )
}
