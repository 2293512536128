import { createFileRoute } from '@tanstack/react-router'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import Box from '@mui/material/Box'

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  const envText =
    import.meta.env.VITE_ENV !== 'production'
      ? `(${import.meta.env.VITE_ENV})`
      : ''

  return (
    <PageLayout title={`Plantbid v1.5 ${envText}`}>
      <Box></Box>
    </PageLayout>
  )
}
