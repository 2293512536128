/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SentryTestImport } from './routes/sentry-test'
import { Route as Auth0CallbackImport } from './routes/auth0-callback'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as AuthenticatedAuthTestImport } from './routes/_authenticated/auth-test'
import { Route as AuthenticatedRfpsIndexImport } from './routes/_authenticated/rfps.index'
import { Route as AuthenticatedQuotesIndexImport } from './routes/_authenticated/quotes.index'
import { Route as AuthenticatedPlantsIndexImport } from './routes/_authenticated/plants.index'
import { Route as AuthenticatedPlantListsIndexImport } from './routes/_authenticated/plant-lists.index'
import { Route as AuthenticatedOrdersIndexImport } from './routes/_authenticated/orders.index'
import { Route as AuthenticatedJobsIndexImport } from './routes/_authenticated/jobs.index'
import { Route as AuthenticatedContactsIndexImport } from './routes/_authenticated/contacts.index'
import { Route as AuthenticatedPlantsPlantListIdImport } from './routes/_authenticated/plants.$plantListId'
import { Route as AuthenticatedPlantListsPlantListIdImport } from './routes/_authenticated/plant-lists.$plantListId'
import { Route as AuthenticatedContactsContactIdImport } from './routes/_authenticated/contacts.$contactId'
import { Route as AuthenticatedRfpsDraftRfpIdImport } from './routes/_authenticated/rfps.draft.$rfpId'

// Create/Update Routes

const SentryTestRoute = SentryTestImport.update({
  id: '/sentry-test',
  path: '/sentry-test',
  getParentRoute: () => rootRoute,
} as any)

const Auth0CallbackRoute = Auth0CallbackImport.update({
  id: '/auth0-callback',
  path: '/auth0-callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedAuthTestRoute = AuthenticatedAuthTestImport.update({
  id: '/auth-test',
  path: '/auth-test',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedRfpsIndexRoute = AuthenticatedRfpsIndexImport.update({
  id: '/rfps/',
  path: '/rfps/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedQuotesIndexRoute = AuthenticatedQuotesIndexImport.update({
  id: '/quotes/',
  path: '/quotes/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPlantsIndexRoute = AuthenticatedPlantsIndexImport.update({
  id: '/plants/',
  path: '/plants/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedPlantListsIndexRoute =
  AuthenticatedPlantListsIndexImport.update({
    id: '/plant-lists/',
    path: '/plant-lists/',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedOrdersIndexRoute = AuthenticatedOrdersIndexImport.update({
  id: '/orders/',
  path: '/orders/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedJobsIndexRoute = AuthenticatedJobsIndexImport.update({
  id: '/jobs/',
  path: '/jobs/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedContactsIndexRoute = AuthenticatedContactsIndexImport.update(
  {
    id: '/contacts/',
    path: '/contacts/',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedPlantsPlantListIdRoute =
  AuthenticatedPlantsPlantListIdImport.update({
    id: '/plants/$plantListId',
    path: '/plants/$plantListId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedPlantListsPlantListIdRoute =
  AuthenticatedPlantListsPlantListIdImport.update({
    id: '/plant-lists/$plantListId',
    path: '/plant-lists/$plantListId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedContactsContactIdRoute =
  AuthenticatedContactsContactIdImport.update({
    id: '/contacts/$contactId',
    path: '/contacts/$contactId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedRfpsDraftRfpIdRoute =
  AuthenticatedRfpsDraftRfpIdImport.update({
    id: '/rfps/draft/$rfpId',
    path: '/rfps/draft/$rfpId',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/auth0-callback': {
      id: '/auth0-callback'
      path: '/auth0-callback'
      fullPath: '/auth0-callback'
      preLoaderRoute: typeof Auth0CallbackImport
      parentRoute: typeof rootRoute
    }
    '/sentry-test': {
      id: '/sentry-test'
      path: '/sentry-test'
      fullPath: '/sentry-test'
      preLoaderRoute: typeof SentryTestImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/auth-test': {
      id: '/_authenticated/auth-test'
      path: '/auth-test'
      fullPath: '/auth-test'
      preLoaderRoute: typeof AuthenticatedAuthTestImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/contacts/$contactId': {
      id: '/_authenticated/contacts/$contactId'
      path: '/contacts/$contactId'
      fullPath: '/contacts/$contactId'
      preLoaderRoute: typeof AuthenticatedContactsContactIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/plant-lists/$plantListId': {
      id: '/_authenticated/plant-lists/$plantListId'
      path: '/plant-lists/$plantListId'
      fullPath: '/plant-lists/$plantListId'
      preLoaderRoute: typeof AuthenticatedPlantListsPlantListIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/plants/$plantListId': {
      id: '/_authenticated/plants/$plantListId'
      path: '/plants/$plantListId'
      fullPath: '/plants/$plantListId'
      preLoaderRoute: typeof AuthenticatedPlantsPlantListIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/contacts/': {
      id: '/_authenticated/contacts/'
      path: '/contacts'
      fullPath: '/contacts'
      preLoaderRoute: typeof AuthenticatedContactsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/jobs/': {
      id: '/_authenticated/jobs/'
      path: '/jobs'
      fullPath: '/jobs'
      preLoaderRoute: typeof AuthenticatedJobsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/orders/': {
      id: '/_authenticated/orders/'
      path: '/orders'
      fullPath: '/orders'
      preLoaderRoute: typeof AuthenticatedOrdersIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/plant-lists/': {
      id: '/_authenticated/plant-lists/'
      path: '/plant-lists'
      fullPath: '/plant-lists'
      preLoaderRoute: typeof AuthenticatedPlantListsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/plants/': {
      id: '/_authenticated/plants/'
      path: '/plants'
      fullPath: '/plants'
      preLoaderRoute: typeof AuthenticatedPlantsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/quotes/': {
      id: '/_authenticated/quotes/'
      path: '/quotes'
      fullPath: '/quotes'
      preLoaderRoute: typeof AuthenticatedQuotesIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/rfps/': {
      id: '/_authenticated/rfps/'
      path: '/rfps'
      fullPath: '/rfps'
      preLoaderRoute: typeof AuthenticatedRfpsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/rfps/draft/$rfpId': {
      id: '/_authenticated/rfps/draft/$rfpId'
      path: '/rfps/draft/$rfpId'
      fullPath: '/rfps/draft/$rfpId'
      preLoaderRoute: typeof AuthenticatedRfpsDraftRfpIdImport
      parentRoute: typeof AuthenticatedImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedAuthTestRoute: typeof AuthenticatedAuthTestRoute
  AuthenticatedContactsContactIdRoute: typeof AuthenticatedContactsContactIdRoute
  AuthenticatedPlantListsPlantListIdRoute: typeof AuthenticatedPlantListsPlantListIdRoute
  AuthenticatedPlantsPlantListIdRoute: typeof AuthenticatedPlantsPlantListIdRoute
  AuthenticatedContactsIndexRoute: typeof AuthenticatedContactsIndexRoute
  AuthenticatedJobsIndexRoute: typeof AuthenticatedJobsIndexRoute
  AuthenticatedOrdersIndexRoute: typeof AuthenticatedOrdersIndexRoute
  AuthenticatedPlantListsIndexRoute: typeof AuthenticatedPlantListsIndexRoute
  AuthenticatedPlantsIndexRoute: typeof AuthenticatedPlantsIndexRoute
  AuthenticatedQuotesIndexRoute: typeof AuthenticatedQuotesIndexRoute
  AuthenticatedRfpsIndexRoute: typeof AuthenticatedRfpsIndexRoute
  AuthenticatedRfpsDraftRfpIdRoute: typeof AuthenticatedRfpsDraftRfpIdRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedAuthTestRoute: AuthenticatedAuthTestRoute,
  AuthenticatedContactsContactIdRoute: AuthenticatedContactsContactIdRoute,
  AuthenticatedPlantListsPlantListIdRoute:
    AuthenticatedPlantListsPlantListIdRoute,
  AuthenticatedPlantsPlantListIdRoute: AuthenticatedPlantsPlantListIdRoute,
  AuthenticatedContactsIndexRoute: AuthenticatedContactsIndexRoute,
  AuthenticatedJobsIndexRoute: AuthenticatedJobsIndexRoute,
  AuthenticatedOrdersIndexRoute: AuthenticatedOrdersIndexRoute,
  AuthenticatedPlantListsIndexRoute: AuthenticatedPlantListsIndexRoute,
  AuthenticatedPlantsIndexRoute: AuthenticatedPlantsIndexRoute,
  AuthenticatedQuotesIndexRoute: AuthenticatedQuotesIndexRoute,
  AuthenticatedRfpsIndexRoute: AuthenticatedRfpsIndexRoute,
  AuthenticatedRfpsDraftRfpIdRoute: AuthenticatedRfpsDraftRfpIdRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/auth0-callback': typeof Auth0CallbackRoute
  '/sentry-test': typeof SentryTestRoute
  '/auth-test': typeof AuthenticatedAuthTestRoute
  '/contacts/$contactId': typeof AuthenticatedContactsContactIdRoute
  '/plant-lists/$plantListId': typeof AuthenticatedPlantListsPlantListIdRoute
  '/plants/$plantListId': typeof AuthenticatedPlantsPlantListIdRoute
  '/contacts': typeof AuthenticatedContactsIndexRoute
  '/jobs': typeof AuthenticatedJobsIndexRoute
  '/orders': typeof AuthenticatedOrdersIndexRoute
  '/plant-lists': typeof AuthenticatedPlantListsIndexRoute
  '/plants': typeof AuthenticatedPlantsIndexRoute
  '/quotes': typeof AuthenticatedQuotesIndexRoute
  '/rfps': typeof AuthenticatedRfpsIndexRoute
  '/rfps/draft/$rfpId': typeof AuthenticatedRfpsDraftRfpIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/auth0-callback': typeof Auth0CallbackRoute
  '/sentry-test': typeof SentryTestRoute
  '/auth-test': typeof AuthenticatedAuthTestRoute
  '/contacts/$contactId': typeof AuthenticatedContactsContactIdRoute
  '/plant-lists/$plantListId': typeof AuthenticatedPlantListsPlantListIdRoute
  '/plants/$plantListId': typeof AuthenticatedPlantsPlantListIdRoute
  '/contacts': typeof AuthenticatedContactsIndexRoute
  '/jobs': typeof AuthenticatedJobsIndexRoute
  '/orders': typeof AuthenticatedOrdersIndexRoute
  '/plant-lists': typeof AuthenticatedPlantListsIndexRoute
  '/plants': typeof AuthenticatedPlantsIndexRoute
  '/quotes': typeof AuthenticatedQuotesIndexRoute
  '/rfps': typeof AuthenticatedRfpsIndexRoute
  '/rfps/draft/$rfpId': typeof AuthenticatedRfpsDraftRfpIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/auth0-callback': typeof Auth0CallbackRoute
  '/sentry-test': typeof SentryTestRoute
  '/_authenticated/auth-test': typeof AuthenticatedAuthTestRoute
  '/_authenticated/contacts/$contactId': typeof AuthenticatedContactsContactIdRoute
  '/_authenticated/plant-lists/$plantListId': typeof AuthenticatedPlantListsPlantListIdRoute
  '/_authenticated/plants/$plantListId': typeof AuthenticatedPlantsPlantListIdRoute
  '/_authenticated/contacts/': typeof AuthenticatedContactsIndexRoute
  '/_authenticated/jobs/': typeof AuthenticatedJobsIndexRoute
  '/_authenticated/orders/': typeof AuthenticatedOrdersIndexRoute
  '/_authenticated/plant-lists/': typeof AuthenticatedPlantListsIndexRoute
  '/_authenticated/plants/': typeof AuthenticatedPlantsIndexRoute
  '/_authenticated/quotes/': typeof AuthenticatedQuotesIndexRoute
  '/_authenticated/rfps/': typeof AuthenticatedRfpsIndexRoute
  '/_authenticated/rfps/draft/$rfpId': typeof AuthenticatedRfpsDraftRfpIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/auth0-callback'
    | '/sentry-test'
    | '/auth-test'
    | '/contacts/$contactId'
    | '/plant-lists/$plantListId'
    | '/plants/$plantListId'
    | '/contacts'
    | '/jobs'
    | '/orders'
    | '/plant-lists'
    | '/plants'
    | '/quotes'
    | '/rfps'
    | '/rfps/draft/$rfpId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/auth0-callback'
    | '/sentry-test'
    | '/auth-test'
    | '/contacts/$contactId'
    | '/plant-lists/$plantListId'
    | '/plants/$plantListId'
    | '/contacts'
    | '/jobs'
    | '/orders'
    | '/plant-lists'
    | '/plants'
    | '/quotes'
    | '/rfps'
    | '/rfps/draft/$rfpId'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/auth0-callback'
    | '/sentry-test'
    | '/_authenticated/auth-test'
    | '/_authenticated/contacts/$contactId'
    | '/_authenticated/plant-lists/$plantListId'
    | '/_authenticated/plants/$plantListId'
    | '/_authenticated/contacts/'
    | '/_authenticated/jobs/'
    | '/_authenticated/orders/'
    | '/_authenticated/plant-lists/'
    | '/_authenticated/plants/'
    | '/_authenticated/quotes/'
    | '/_authenticated/rfps/'
    | '/_authenticated/rfps/draft/$rfpId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  Auth0CallbackRoute: typeof Auth0CallbackRoute
  SentryTestRoute: typeof SentryTestRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  Auth0CallbackRoute: Auth0CallbackRoute,
  SentryTestRoute: SentryTestRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/auth0-callback",
        "/sentry-test"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/auth-test",
        "/_authenticated/contacts/$contactId",
        "/_authenticated/plant-lists/$plantListId",
        "/_authenticated/plants/$plantListId",
        "/_authenticated/contacts/",
        "/_authenticated/jobs/",
        "/_authenticated/orders/",
        "/_authenticated/plant-lists/",
        "/_authenticated/plants/",
        "/_authenticated/quotes/",
        "/_authenticated/rfps/",
        "/_authenticated/rfps/draft/$rfpId"
      ]
    },
    "/auth0-callback": {
      "filePath": "auth0-callback.tsx"
    },
    "/sentry-test": {
      "filePath": "sentry-test.tsx"
    },
    "/_authenticated/auth-test": {
      "filePath": "_authenticated/auth-test.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/contacts/$contactId": {
      "filePath": "_authenticated/contacts.$contactId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/plant-lists/$plantListId": {
      "filePath": "_authenticated/plant-lists.$plantListId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/plants/$plantListId": {
      "filePath": "_authenticated/plants.$plantListId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/contacts/": {
      "filePath": "_authenticated/contacts.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/jobs/": {
      "filePath": "_authenticated/jobs.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/orders/": {
      "filePath": "_authenticated/orders.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/plant-lists/": {
      "filePath": "_authenticated/plant-lists.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/plants/": {
      "filePath": "_authenticated/plants.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/quotes/": {
      "filePath": "_authenticated/quotes.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/rfps/": {
      "filePath": "_authenticated/rfps.index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/rfps/draft/$rfpId": {
      "filePath": "_authenticated/rfps.draft.$rfpId.tsx",
      "parent": "/_authenticated"
    }
  }
}
ROUTE_MANIFEST_END */
