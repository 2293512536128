import type {
  CreateOrganizationLicenseRequestBody,
  Organization,
  OrganizationLicense,
  OrganizationLocation,
  OrganizationMember,
  OrganizationRequestBody,
  User,
} from '@/types.ts'

import api from './api.ts'
import { CreateOrganizationLocationRequestBody } from '@/types.ts'

export async function getOrganizations() {
  const response = await api.get(`/v1/core/organizations/`)
  return response.data as Organization[]
}

export async function getOrganizationById(organizationId: string | undefined) {
  if (!organizationId) {
    throw new Error('Organization ID is required')
  }
  const response = await api.get(`/v1/core/organizations/${organizationId}`)
  return response.data as Organization
}

export async function createOrganization(organization: Partial<Organization>) {
  const response = await api.post(`/v1/core/organizations/`, organization, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as Organization
}

export async function updateOrganization(
  organization: OrganizationRequestBody
) {
  const response = await api.put(
    `/v1/core/organizations/${organization.id}`,
    organization,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data as Organization
}

/** Organization Locations */
export async function createOrganizationLocation(
  location: CreateOrganizationLocationRequestBody
) {
  const response = await api.post(`/v1/core/organization_location/`, location, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as OrganizationLocation
}

export async function updateOrganizationLocation(
  location: OrganizationLocation
) {
  const response = await api.put(
    `/v1/core/organization_location/${location.id}`,
    location,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data as OrganizationLocation
}

export async function deleteOrganizationLocation(locationId: string) {
  const response = await api.delete(
    `/v1/core/organization_location/${locationId}`
  )
  return response.status
}

/** Organization Licenses */
export async function createOrganizationLicense(
  license: CreateOrganizationLicenseRequestBody
) {
  const response = await api.post(`/v1/core/organization_licenses/`, license, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as OrganizationLicense
}

export async function deleteOrganizationLicense(licenseId: string) {
  const response = await api.delete(
    `/v1/core/organization_licenses/${licenseId}`
  )
  return response.status
}

/** Team Members */
type Role = {
  id: string
  name: string
  permissions: string[]
  custom_role: boolean
}
export async function getRoles() {
  const response = await api.get(`/v1/core/custom_role/`)
  return response.data as Role[]
}

export async function inviteUser({
  email,
  roles,
  organization,
}: {
  email: string
  roles: string[]
  organization: string
}) {
  const response = await api.post(
    `/auth/users/invite/`,
    {
      email,
      roles,
      organization,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data as User
}

export async function resendInvite({
  userId,
  organizationId,
}: {
  userId: string
  organizationId: string
}) {
  const response = await api.get(
    `/auth/users/resend_invite/${organizationId}/${userId}`
  )
  return response.data as User
}

export async function assignRole({
  organization_member_id,
  roles,
  organization,
}: {
  organization_member_id: string
  roles: string[]
  organization: string
}) {
  const response = await api.put(
    `/v1/core/organization_members/${organization_member_id}/`,
    {
      id: organization_member_id,
      organization,
      roles,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return response.data as OrganizationMember
}

export async function removeMember(member_id: string) {
  const response = await api.delete(
    `/v1/core/organization_members/${member_id}`
  )
  return response.status
}

export async function transferOwnership({
  user_id,
  organization_id,
}: {
  user_id: string
  organization_id: string
}) {
  const response = await api.get(
    `/v1/core/organization_members/transfer_ownership/${organization_id}/${user_id}`
  )
  return response.data as OrganizationMember
}
