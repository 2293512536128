import {
  AbstractDetailsComponent,
  AbstractDetailsTextContent,
} from '@/components/rfps/abstract-details-card.tsx'
import {
  AbstractDetailsComponentProps,
  JobDetailsProps,
} from '@/components/rfps/types.ts'
import { Stack } from '@mui/material'
import { VisibilityOff } from '@mui/icons-material'

export const NotVisibleToVendors = () => {
  return (
    <Stack direction="row" sx={{ gap: 1, color: '#ccc' }}>
      <VisibilityOff fontSize="small" />
      <AbstractDetailsTextContent text="Not visible to vendors" />
    </Stack>
  )
}

export const JobDetails = ({ rfpData }: JobDetailsProps) => {
  console.log('JobDetails rfpData', rfpData)

  const jobNameData = () => {
    const content = (
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <AbstractDetailsTextContent text="n/a" />
        <NotVisibleToVendors />
      </Stack>
    )
    return { name: 'Job Name', content: content }
  }

  const jobIdData = () => {
    const content = <AbstractDetailsTextContent text="n/a" />
    return { name: 'Job ID', content: content }
  }

  const jobDateData = () => {
    const content = <AbstractDetailsTextContent text="n/a" />
    return { name: 'Job Date', content: content }
  }

  const rfpStageData = () => {
    const content = <AbstractDetailsTextContent text="n/a" />
    return { name: 'Stage', content: content }
  }

  const detailsData = {
    name: 'Job Details',
    content: [jobNameData(), jobIdData(), jobDateData(), rfpStageData()],
  } as AbstractDetailsComponentProps

  return AbstractDetailsComponent(detailsData)
}
