import { useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { DarkPrimaryButton, PrimaryCancelButton } from '../base/buttons/buttons'

interface CropImageModalProps {
  open: boolean
  onClose: () => void
  imageFile: File
  onCropComplete: (croppedFile: File) => void
}

export default function CropImageModal({
  open,
  onClose,
  imageFile,
  onCropComplete,
}: CropImageModalProps) {
  const [crop, setCrop] = useState<Crop>()
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null)

  const handleCropComplete = async () => {
    if (!crop || !imageRef) return

    const canvas = document.createElement('canvas')
    const scaleX = imageRef.naturalWidth / imageRef.width
    const scaleY = imageRef.naturalHeight / imageRef.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    if (ctx) {
      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      )

      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], imageFile.name, {
            type: imageFile.type,
          })
          onCropComplete(croppedFile)
          onClose()
        }
      }, imageFile.type)
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      aria-labelledby="crop-image-modal-title"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          bgcolor: 'white',
          boxShadow: 24,
          p: 5,
          borderRadius: 2,
        }}
      >
        <Typography id="crop-image-modal-title" variant="modalHeader1" mb={5}>
          Edit Image
        </Typography>
        <Box sx={{ mb: 3 }}>
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img
              src={URL.createObjectURL(imageFile)}
              alt="Crop preview"
              style={{ maxWidth: '100%', maxHeight: '60vh' }}
              onLoad={(e) => setImageRef(e.currentTarget)}
            />
          </ReactCrop>
        </Box>
        <Box display="flex" justifyContent="end" gap={1}>
          <PrimaryCancelButton onClick={onClose}>Cancel</PrimaryCancelButton>
          <DarkPrimaryButton onClick={handleCropComplete}>
            Crop & Save
          </DarkPrimaryButton>
        </Box>
      </Box>
    </Modal>
  )
}
