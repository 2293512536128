import Box from '@mui/material/Box'
import {
  AbstractDetailsComponentProps,
  RFPDetailsProps,
} from '@/components/rfps/types.ts'
import {
  AbstractDetailsComponent,
  AbstractDetailsTextContent,
} from '@/components/rfps/abstract-details-card.tsx'
import { FullMothDayYearFormat, UserFullName } from '@/lib/utils.ts'

export const RFPDetails = ({ rfpData }: RFPDetailsProps) => {
  const ownerData = () => {
    const defaultText = 'None'
    const fullName = UserFullName(rfpData?.owner) || defaultText
    const content = <AbstractDetailsTextContent text={fullName} />
    return { name: 'Owner', content: content }
  }

  const collaboratorsData = () => {
    const defaultText = 'None'
    const content =
      rfpData?.collaborators.length > 0
        ? rfpData.collaborators.map((collaborator: any) => {
            return <Box>{collaborator?.email}</Box>
          })
        : defaultText
    return { name: 'Collaborators', content: content }
  }

  const quoteData = () => {
    const formattedDate = FullMothDayYearFormat(rfpData.quote_needed_by)
    const content = <AbstractDetailsTextContent text={formattedDate} />
    return { name: 'Quote Needed By', content: content }
  }

  const deliveryData = () => {
    const defaultText = 'TBD'
    const formattedDate =
      FullMothDayYearFormat(rfpData.delivery_date) || defaultText
    const content = <AbstractDetailsTextContent text={formattedDate} />
    return { name: 'Delivery Date', content: content }
  }

  const detailsData = {
    name: 'RFP Details',
    content: [ownerData(), collaboratorsData(), quoteData(), deliveryData()],
  } as AbstractDetailsComponentProps

  return AbstractDetailsComponent(detailsData)
}
