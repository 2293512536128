import { useState, ChangeEvent, Fragment } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  ContactAddressTypes,
  ContactEmailTypes,
  ContactPhoneTypes,
  US_STATES,
} from '@/constants.ts'
import {
  ContactEmail,
  ContactPhone,
  CreateOrganizationLocationRequestBody,
  OrganizationLocation,
} from '@/types.ts'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Plus } from 'lucide-react'
import { initialFormEmail, initialFormPhone } from '@/seed_form_data.ts'
import Alert from '@mui/material/Alert'
import { countryFormattedPhoneNumber } from '@/lib/utils.ts'
import Button from '@mui/material/Button'
import {
  DarkPrimaryButton,
  PrimaryCancelButton,
} from '@/components/ui/base/buttons/buttons.tsx'

export const USStatesMenuProps = {
  PaperProps: { style: { maxHeight: 200 } },
  anchorOrigin: { vertical: 'bottom' as const, horizontal: 'left' as const },
  transformOrigin: { vertical: 'top' as const, horizontal: 'left' as const },
}

type OrganizationLocationFormProps = {
  validationError: string | null
  location: OrganizationLocation | CreateOrganizationLocationRequestBody
  toggleLocationDetails: (value: string) => void
  submitLocationDetails: (
    location: OrganizationLocation | CreateOrganizationLocationRequestBody
  ) => void
}

export default function OrganizationLocationForm({
  location,
  validationError,
  toggleLocationDetails,
  submitLocationDetails,
}: OrganizationLocationFormProps) {
  const [formStreetAddress, setFormStreetAddress] = useState(
    location.address.address.street
  )
  const [formLocationDetails, setFormLocationDetails] = useState<
    OrganizationLocation | CreateOrganizationLocationRequestBody
  >(location)
  const [formSuiteNumber, setFormSuiteNumber] = useState('')
  const [phoneNumbers, setPhoneNumbers] = useState(
    location.phone && location.phone.length
      ? [...location.phone]
      : [initialFormPhone]
  )
  const [emailAddresses, setEmailAddresses] = useState(
    location.email && location.email.length
      ? [...location.email]
      : [initialFormEmail]
  )

  const handleUpdatedStateResponseType = (
    updatedState: any
  ): OrganizationLocation | CreateOrganizationLocationRequestBody => {
    if (updatedState?.id) {
      return updatedState as OrganizationLocation
    } else {
      return updatedState as CreateOrganizationLocationRequestBody
    }
  }

  const handleEmailOrPhoneValueChange = (
    e:
      | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<unknown>,
    index: number,
    contact_field: string
  ) => {
    const { name, value } = e.target
    if (contact_field === 'phone') {
      const updatedPhoneNumbers = [...phoneNumbers]
      let parsedValue = value
      if (name === 'number') {
        parsedValue = countryFormattedPhoneNumber(value as string)
      }
      updatedPhoneNumbers[index] = {
        ...updatedPhoneNumbers[index],
        [name]: parsedValue,
      }
      setPhoneNumbers(updatedPhoneNumbers as ContactPhone[])
      setFormLocationDetails((prevState) => {
        const updatedState = {
          ...prevState,
          phone: updatedPhoneNumbers,
        }
        return handleUpdatedStateResponseType(updatedState)
      })
    } else {
      const updatedEmailAddresses = [...emailAddresses]
      updatedEmailAddresses[index] = {
        ...updatedEmailAddresses[index],
        [name]: value,
      }
      setEmailAddresses(updatedEmailAddresses as ContactEmail[])
      setFormLocationDetails((prevState) => {
        const updatedState = {
          ...prevState,
          email: updatedEmailAddresses,
        }
        return handleUpdatedStateResponseType(updatedState)
      })
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target
    setFormLocationDetails(
      (
        prevState: OrganizationLocation | CreateOrganizationLocationRequestBody
      ) => {
        const updatedState = {
          ...prevState,
          [name]: type === 'checkbox' ? checked : value,
        }
        return handleUpdatedStateResponseType(updatedState)
      }
    )
  }

  const addEmptyPhoneOrEmail = (type: 'phone' | 'email') => {
    if (type === 'phone') {
      setPhoneNumbers([...phoneNumbers, initialFormPhone])
    } else {
      setEmailAddresses([...emailAddresses, initialFormEmail])
    }
  }

  const handleAddressInputChange = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>
  ) => {
    const { name, value } = e.target
    const isStreetOrSuite = name === 'street' || name === 'suite_number'
    const updatedStreetAddress = name === 'street' ? value : formStreetAddress
    const updatedSuiteNumber = name === 'suite_number' ? value : formSuiteNumber

    const updatedValue = isStreetOrSuite
      ? `${updatedStreetAddress} ${updatedSuiteNumber}`.trim()
      : value

    const address_key = isStreetOrSuite ? 'street' : name

    if (isStreetOrSuite) {
      name === 'street'
        ? setFormStreetAddress(value as string)
        : setFormSuiteNumber(value as string)
    }

    // Update the formLocationDetails state
    setFormLocationDetails((prevState) => {
      const updatedFieldState =
        name === 'address_type'
          ? {
              ...prevState.address,
              [address_key]: value,
            }
          : {
              ...prevState.address,
              address: {
                ...prevState.address.address,
                [address_key]: updatedValue,
              },
            }

      const updatedState = {
        ...prevState,
        address: updatedFieldState,
      }

      return handleUpdatedStateResponseType(updatedState)
    })
  }

  return (
    <Box className="border-b py-8">
      <Typography variant="tabSection" gutterBottom>
        {formLocationDetails?.id ? 'Edit Location' : 'New Location'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            name="address_type"
            fullWidth
            required
            value={
              formLocationDetails?.address?.address_type ||
              ContactAddressTypes.HEADQUARTERS
            }
            onChange={handleAddressInputChange}
          >
            {Object.values(ContactAddressTypes).map((value, address_index) => (
              <MenuItem key={`address-type-${address_index}`} value={value}>
                {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="name"
            label="Location Name (optional)"
            placeholder="Name"
            fullWidth
            value={formLocationDetails?.name || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            name="street"
            label="Street Address"
            placeholder="Address"
            fullWidth
            value={formStreetAddress}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="suite_number"
            label="Suite"
            placeholder="Suite #"
            fullWidth
            value={formSuiteNumber}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="city"
            label="City"
            placeholder="City Name"
            fullWidth
            value={formLocationDetails?.address?.address?.city || ''}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="state"
            label="State"
            fullWidth
            required
            select
            value={formLocationDetails?.address?.address?.state || ''}
            onChange={handleAddressInputChange}
            slotProps={{ select: { MenuProps: USStatesMenuProps } }}
          >
            {US_STATES.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="zip"
            label="Zip Code"
            required
            fullWidth
            value={formLocationDetails?.address?.address?.zip || ''}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="tabSection" marginTop={2}>
            Location Contact Details
          </Typography>
        </Grid>
        {phoneNumbers.map((phone, index) => {
          return (
            <Fragment key={`phone-${index}`}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="number"
                  label="Phone"
                  placeholder="+ (---) --- ----"
                  fullWidth
                  value={phone.number || ''}
                  onChange={(e) =>
                    handleEmailOrPhoneValueChange(e, index, 'phone')
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  name="extension"
                  label="Extension"
                  placeholder="-"
                  fullWidth
                  value={phone.extension || ''}
                  onChange={(e) =>
                    handleEmailOrPhoneValueChange(e, index, 'phone')
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  name="contact_type"
                  fullWidth
                  value={phone.contact_type || ContactPhoneTypes.WORK}
                  onChange={(e) =>
                    handleEmailOrPhoneValueChange(e, index, 'phone')
                  }
                >
                  {Object.values(ContactPhoneTypes).map(
                    (value, phone_index) => (
                      <MenuItem key={`phone-type-${phone_index}`} value={value}>
                        {value.charAt(0).toUpperCase() +
                          value.slice(1).toLowerCase()}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Grid>
            </Fragment>
          )
        })}
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', paddingTop: '3px !important' }}
        >
          <Button onClick={() => addEmptyPhoneOrEmail('phone')} color="inherit">
            <Box display="flex" alignItems="center" gap={1}>
              <Plus size={14} />
              <Typography variant="button">Add another phone number</Typography>
            </Box>
          </Button>
        </Grid>
        {emailAddresses.map((email, index) => {
          return (
            <Fragment key={`email-${index}`}>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  name="address"
                  label="Email"
                  placeholder="info@acme.co"
                  fullWidth
                  value={email.address || ''}
                  onChange={(e) =>
                    handleEmailOrPhoneValueChange(e, index, 'email')
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  name="contact_type"
                  fullWidth
                  value={email.contact_type || ContactEmailTypes.WORK}
                  onChange={(e) =>
                    handleEmailOrPhoneValueChange(e, index, 'email')
                  }
                >
                  {Object.values(ContactEmailTypes).map(
                    (value, phone_index) => (
                      <MenuItem key={`email-type-${phone_index}`} value={value}>
                        {value.charAt(0).toUpperCase() +
                          value.slice(1).toLowerCase()}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Grid>
            </Fragment>
          )
        })}
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', paddingTop: '3px !important' }}
        >
          <Button onClick={() => addEmptyPhoneOrEmail('email')} color="inherit">
            <Box display="flex" alignItems="center" gap={1}>
              <Plus size={14} />
              <Typography variant="button">
                Add another email address
              </Typography>
            </Box>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gap={2} marginTop={2}>
            <DarkPrimaryButton
              onClick={() => submitLocationDetails(formLocationDetails)}
            >
              <Typography variant="button">
                {formLocationDetails?.id ? 'Save' : 'Add Location'}
              </Typography>
            </DarkPrimaryButton>
            <PrimaryCancelButton onClick={() => toggleLocationDetails('')}>
              <Typography variant="button">Cancel</Typography>
            </PrimaryCancelButton>
          </Box>
        </Grid>
        {validationError && (
          <Grid item xs={12}>
            <Alert severity="error" className="my-4">
              {validationError}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
