import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { NotepadText } from 'lucide-react'
import { RFPDrawer } from '@/components/rfps/draft/drawer/rfp-drawer-right.tsx'
import { Stack } from '@mui/material'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import { type ReactNode } from 'react'

interface BuildRFPStepProps {
  rfpData: any
  onUpdateRfpData: (updates: Partial<any>) => void
}

export default function BuildRFPStep({
  rfpData,
  onUpdateRfpData,
}: BuildRFPStepProps) {
  const addInternalNote = () => {
    const condition = false
    if (condition) {
      onUpdateRfpData({})
    }
  }

  const RFPPageHeader = () => {
    interface StyledButtonProps {
      onClick: () => void
      startIcon: ReactNode
      text: string
      variant?: 'outlined' | 'contained' | 'text'
    }

    function StyledButton({
      onClick,
      startIcon,
      text,
      variant = 'outlined',
    }: StyledButtonProps) {
      return (
        <Button
          onClick={onClick}
          startIcon={startIcon}
          variant={variant}
          sx={{
            p: 1,
            borderColor: '#C4CAD0',
            backgroundColor: 'white',
            color: '#181818',
            '&:hover': {
              backgroundColor: 'white',
              borderColor: '#C4CAD0',
            },
          }}
        >
          {text}
        </Button>
      )
    }

    return (
      <>
        <Typography variant="h4" fontWeight={400}>
          Add Plants &amp; RFP Details
        </Typography>
        <Box pr={1}>
          <StyledButton
            onClick={addInternalNote}
            startIcon={<NotepadText />}
            text={'Add Internal Note'}
          />
        </Box>
      </>
    )
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Stack
          direction="row"
          sx={{
            p: 3.75,
            pb: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <RFPPageHeader />
        </Stack>
        <Box
          sx={{
            px: 3,
            pb: 3,
            flexGrow: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
            height: 0, // Take up full height of parent
          }}
        >
          <RFPPageContent rfpData={rfpData} onUpdateRfpData={onUpdateRfpData} />
        </Box>
      </Box>
      <RFPDrawer />
    </Box>
  )
}
