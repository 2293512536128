import { useSortable } from '@dnd-kit/sortable'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

type PlaceholderDragDropRowProps = { file: string }

const PlaceholderDragDropRow = ({ file }: PlaceholderDragDropRowProps) => {
  // Call useSortable at the top level of the component
  const { attributes, listeners, setNodeRef } = useSortable({
    id: `file-${file}-placeholder`,
    disabled: true,
  })

  return (
    <TableRow ref={setNodeRef} {...attributes} {...listeners}>
      <TableCell
        colSpan={6}
        sx={{
          border: '2px dashed #ccc',
          textAlign: 'center',
        }}
      >
        Drop plants here
      </TableCell>
    </TableRow>
  )
}

export default PlaceholderDragDropRow
