import Box from '@mui/material/Box'
import { CardContent, Skeleton, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

const PlaceHolderCard = () => {
  return (
    <CardContent sx={{ borderTop: '1px solid #ddd', mt: 2 }}>
      <Typography variant="subtitle1" color="text.secondary" mb={1}>
        History
      </Typography>
      {[...Array(3)].map((_, index) => (
        <Box key={index} sx={{ p: 2, mb: 2 }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Box>
            <Skeleton variant="text" width={120} height={20} />
            <Skeleton variant="text" width="80%" height={20} />
          </Box>
        </Box>
      ))}
    </CardContent>
  )
}

export const RFPHistory = () => {
  return (
    <Stack>
      <PlaceHolderCard />
    </Stack>
  )
}
