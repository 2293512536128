import { useDebounce } from '@uidotdev/usehooks'
import { useEffect, useState, useRef } from 'react'
import type { ElasticsearchResponse } from '@/types'
import { useQuery } from '@tanstack/react-query'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { Search, X } from 'lucide-react'
import { search } from '@/api/elasticsearch'
import { useOrganization } from '@/contexts/hooks/useOrganization'

export interface SearchBarProps<T> {
  placeholder: string
  searchUrl: string
  onSearchResults?: (response: ElasticsearchResponse<T>) => void
  debounceMs?: number
  minimumSearchLength?: number
  page?: number
  perPage?: number
}
export default function SearchBar<T>({
  placeholder,
  searchUrl,
  onSearchResults,
  debounceMs = 300,
  minimumSearchLength = 3,
  page = 1,
  perPage = 10
}: SearchBarProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null)
  const { selectedOrganization } = useOrganization()
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, debounceMs)

  const { data: searchResponse, isLoading: isSearching } = useQuery({
    queryKey: ['search', searchUrl, debouncedSearchValue, page, perPage],
    queryFn: () => {
      return search<T>(
        searchUrl,
        debouncedSearchValue,
        page,
        perPage,
        selectedOrganization?.id
      )
    },
    enabled: debouncedSearchValue.length > minimumSearchLength,
    // staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false
  })

  useEffect(() => {
    if (inputRef.current && inputRef.current.value.length <= minimumSearchLength) {
      onSearchResults?.(undefined as any)
    } else if (searchResponse) {
      onSearchResults?.(searchResponse)
    }
  }, [searchResponse, onSearchResults, inputRef])

  const handleClearSearch = () => {
    inputRef.current?.focus()
    setSearchValue('')
  }

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      placeholder={placeholder}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        startAdornment: <Search className="mr-1" />,
        endAdornment: (
          <>
            {isSearching && <CircularProgress size={16} />}
            {searchValue && (
              <X
                className="ml-1 h-4 w-4 cursor-pointer hover:opacity-70"
                onClick={handleClearSearch}
              />
            )}
          </>
        ),
      }}
      variant="outlined"
      size="small"
      sx={{ maxWidth: 400 }}
    />
  )
}
