import { type MouseEvent, useState } from 'react'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { CirclePlus } from 'lucide-react'

import NewContactModal from './modals/new-contact-modal.tsx'
import NotImplementedModal from './modals/not-implemented-modal.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { useMutation } from '@tanstack/react-query'
import { createDraftRFP } from '@/api/rfps.ts'
import { useNavigate } from '@tanstack/react-router'

export default function GlobalNewButton() {
  const navigate = useNavigate()
  const { selectedOrganization } = useOrganization()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<string | null>(null)
  const open = Boolean(anchorEl)
  const createDraftRFPMutation = useMutation({
    mutationFn: createDraftRFP,
  })
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleModalOpen = (modalType: string) => {
    setOpenModal(modalType)
    handleClose()
  }
  const handleModalClose = () => {
    setOpenModal(null)
  }
  const handleNewRFP = async () => {
    handleClose()

    // create a draft rfp using the selected organization's id
    if (!selectedOrganization) return
    const newRFP = await createDraftRFPMutation.mutateAsync(
      selectedOrganization.id
    )

    // navigate to the new rfp
    navigate({
      to: '/rfps/draft/$rfpId',
      params: { rfpId: newRFP.id },
      search: { step: 'build-rfp' },
    })
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          minWidth: '50px',
          width: '50px',
          height: '50px',
          padding: 0,
        }}
      >
        <CirclePlus />
      </Button>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { sx: { width: 236 } } }}
      >
        <MenuItem onClick={() => handleModalOpen('new_message')}>
          New Message
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen('add_contact')}>
          Add Contact
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleModalOpen('create_quote')}>
          Create Quote
        </MenuItem>
        <MenuItem onClick={handleNewRFP}>New RFP</MenuItem>
        <Divider />
        <MenuItem onClick={() => handleModalOpen('new_job')}>New Job</MenuItem>
        <MenuItem onClick={() => handleModalOpen('create_order')}>
          Create Order
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen('schedule_delivery')}>
          Schedule Delivery
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen('new_invoice')}>
          New Invoice
        </MenuItem>
      </Menu>

      {openModal === 'add_contact' && (
        <NewContactModal open={true} onClose={handleModalClose} />
      )}

      {/* Placeholder for other modals */}
      {openModal && openModal !== 'add_contact' && (
        <NotImplementedModal open={true} onClose={handleModalClose} />
      )}
    </>
  )
}
