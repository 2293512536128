import { type CSSProperties } from 'react'
import { createTheme, type SimplePaletteColorOptions } from '@mui/material'

// Extend the Typography variants
declare module '@mui/material/styles' {
  interface CustomPalette {
    black: SimplePaletteColorOptions
    lightGrey: SimplePaletteColorOptions
    mediumGrey: SimplePaletteColorOptions
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface Theme {
    borderRadius: {
      sm: string
      md: string
      lg: string
      xl: string
      xxl: string
      circle: string
      pill: string
    }
  }

  interface ThemeOptions {
    borderRadius?: {
      sm?: string
      md?: string
      lg?: string
      xl?: string
      xxl?: string
      circle?: string
      pill?: string
    }
  }

  interface TypographyVariants {
    modalHeader1: CSSProperties
    modalHeader2: CSSProperties
    tabLabel1: CSSProperties
    tabLabel2: CSSProperties
    tabHeader: CSSProperties
    tabSection: CSSProperties
    tabSectionXl: CSSProperties
    tabSubsection: CSSProperties
    navItem: CSSProperties
    strong: CSSProperties
    strongLg: CSSProperties
    tableColumnHeader: CSSProperties
  }

  interface TypographyVariantsOptions {
    modalHeader1?: CSSProperties
    modalHeader2?: CSSProperties
    tabLabel1?: CSSProperties
    tabLabel2?: CSSProperties
    tabHeader?: CSSProperties
    tabSection?: CSSProperties
    tabSectionXl?: CSSProperties
    tabSubsection?: CSSProperties
    navItem?: CSSProperties
    strong?: CSSProperties
    strongLg?: CSSProperties
    tableColumnHeader?: CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    modalHeader1: true
    modalHeader2: true
    tabLabel1: true
    tabLabel2: true
    tabHeader: true
    tabSection: true
    tabSectionXl: true
    tabSubsection: true
    navItem: true
    strong: true
    strongLg: true
    tableColumnHeader: true
  }
}

// Button color overrides
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true
    lightGrey: true
    mediumGrey: true
  }
}

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor: string) =>
  augmentColor({ color: { main: mainColor } })

// Convert pixel values to rem based on 16px browser default
const pxToRem = (px: number) => `${px / 16}rem`

const theme = createTheme({
  borderRadius: {
    sm: '5px',
    md: '10px',
    lg: '15px',
    xl: '20px',
    xxl: '28px',
    circle: '100%',
    pill: '9999px',
  },
  palette: {
    black: createColor('#000000'),
    lightGrey: createColor('#f5f5f5'),
    mediumGrey: createColor('#929292'),
    action: {
      selected: '#F3F3F3',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    // Standard variants
    h1: {
      fontSize: pxToRem(28),
      fontWeight: 600,
    },
    h2: {
      fontSize: pxToRem(22),
      fontWeight: 600,
    },
    h3: {
      fontSize: pxToRem(20),
      fontWeight: 600,
    },
    h4: {
      fontSize: pxToRem(18),
      fontWeight: 600,
    },
    h5: {
      fontSize: pxToRem(16),
      fontWeight: 600,
    },
    h6: {
      fontSize: pxToRem(14),
      fontWeight: 600,
    },
    body1: {
      fontSize: pxToRem(16),
    },
    body2: {
      fontSize: pxToRem(14),
    },
    button: {
      textTransform: 'capitalize',
    },
    // Custom variants
    modalHeader1: {
      fontSize: pxToRem(34),
      fontWeight: 700,
    },
    modalHeader2: {
      fontSize: pxToRem(28),
      fontWeight: 700,
    },
    tabLabel1: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    tabLabel2: {
      fontSize: pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    tabHeader: {
      fontSize: pxToRem(22),
      fontWeight: 700,
    },
    tabSectionXl: {
      fontSize: pxToRem(30),
      fontWeight: 700,
    },
    tabSection: {
      fontSize: pxToRem(16),
      fontWeight: 700,
    },
    tabSubsection: {
      fontSize: pxToRem(13),
      fontWeight: 700,
    },
    navItem: {
      fontSize: pxToRem(13),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    strong: {
      fontSize: pxToRem(17),
      fontWeight: 700,
    },
    strongLg: {
      fontSize: pxToRem(24),
      fontWeight: 700,
    },
    tableColumnHeader: {
      fontSize: pxToRem(14),
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          modalHeader1: 'h2',
          modalHeader2: 'h2',
          tabLabel1: 'p',
          tabLabel2: 'p',
          tabHeader: 'h3',
          tabSection: 'h4',
          tabSubsection: 'h5',
          navItem: 'p',
          strong: 'span',
          strongLg: 'span',
          tableColumnHeader: 'p',
        },
      },
    },
  },
})

export default theme
