import { Typography, Stack, Box } from '@mui/material'
import Paper from '@mui/material/Paper'
import {
  FormatRFPOrganizationDataProps,
  RFPOrganizationDetailsProps,
} from '@/components/rfps/types.ts'
import { ContactEmailTypes, ContactPhoneTypes } from '@/constants.ts'
import { RFPOrganizationLogo } from '@/components/rfps/rfp-organization-logo.tsx'

export const formatRFPOrganizationData = (
  organization: any
): FormatRFPOrganizationDataProps => {
  const defaultData: FormatRFPOrganizationDataProps = {
    logo: organization?.small_logo,
    name: organization?.name,
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    phone: '',
    email: '',
  }

  // Use locations to populate address, phone, and email
  // location with primary is preferred as default
  // work phone and work email are preferred as default
  if (organization.locations && organization.locations.length > 0) {
    const primaryLocation =
      organization.locations.find((loc: any) => loc.primary_location) ||
      organization.locations[0]

    if (primaryLocation) {
      // Address
      if (primaryLocation.address && primaryLocation.address.address) {
        const { street, city, state, zip } = primaryLocation.address.address
        defaultData.address = {
          street: street || '',
          city: city || '',
          state: state || '',
          zip: zip || '',
        }
      }

      // Phone
      if (primaryLocation.phone && primaryLocation.phone.length > 0) {
        const workPhone =
          primaryLocation.phone.find(
            (phone: any) => phone.contact_type === ContactPhoneTypes.WORK
          ) || primaryLocation.phone[0]
        defaultData.phone = workPhone ? workPhone.number : ''
      }

      // Email
      if (primaryLocation.email && primaryLocation.email.length > 0) {
        const workEmail =
          primaryLocation.email.find(
            (email: any) => email.contact_type === ContactEmailTypes.WORK
          ) || primaryLocation.email[0]
        defaultData.email = workEmail ? workEmail.address : ''
      }
    }
  }
  return defaultData
}

export const RfpOrganizationDetails = ({
  organization,
}: RFPOrganizationDetailsProps) => {
  if (!organization) {
    return null
  }

  const orgData = formatRFPOrganizationData(organization)

  const FormattedAddress = () => {
    const addressLine1 = orgData.address.street
    const addressLine2 = [
      orgData.address.city,
      orgData.address.state,
      orgData.address.zip,
    ]
      .filter(Boolean)
      .join(', ')

    return (
      (addressLine1 || addressLine2) && (
        <Stack>
          {addressLine1 && (
            <Typography color="text.secondary" variant="body1">
              {addressLine1}
            </Typography>
          )}
          {addressLine2 && (
            <Typography color="text.secondary" variant="body1">
              {addressLine2}
            </Typography>
          )}
        </Stack>
      )
    )
  }

  return (
    <Paper elevation={0} sx={{ display: 'flex', gap: '20px' }}>
      <Stack>
        <RFPOrganizationLogo logo={orgData.logo} size={60} />
      </Stack>

      <Stack>
        <Typography variant="h2" gutterBottom sx={{ fontWeight: 500 }}>
          {orgData.name}
        </Typography>

        <FormattedAddress />

        {orgData.phone && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography color="text.secondary">{orgData.phone}</Typography>
          </Box>
        )}

        {orgData.email && (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography color="text.secondary">{orgData.email}</Typography>
          </Box>
        )}
      </Stack>
    </Paper>
  )
}
