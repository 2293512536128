import api from './api'

export async function createDraftRFP(organizationId: string) {
  const response = await api.post('/v1/core/draft_rfp/', {
    organization: organizationId,
    name: 'New RFP',
  })

  // TODO: type this
  return response.data as any
}

export async function getDraftRFP(rfpId: string) {
  const response = await api.get(`/v1/core/draft_rfp/${rfpId}`)

  // TODO: type this
  return response.data as any
}

export async function updateDraftRFP(rfpId: string, data: any) {
  const response = await api.patch(`/v1/core/draft_rfp/${rfpId}`, data)

  // TODO: type this
  return response.data as any
}
