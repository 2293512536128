import { Stack } from '@mui/material'
import { PlaceholderCard } from '@/components/rfps/draft/drawer/rfp-drawer-comments.tsx'

export const RFPNotes = () => {
  return (
    <Stack>
      <PlaceholderCard text="Notes" />
    </Stack>
  )
}
