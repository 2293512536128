import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import { PlantListEntry } from '@/types.ts'
import { Fragment, useEffect, useState } from 'react'
import RfpPlantTableItem from '@/components/rfps/rfp-plant-table-item.tsx'

interface RfpPlantTableProps {
  plants: PlantListEntry[]
  onUpdate: (updatedPlants: PlantListEntry[]) => void
}
export default function RfpPlantTable({
  plants,
  onUpdate,
}: RfpPlantTableProps) {
  const [localPlants, setLocalPlants] = useState<PlantListEntry[]>(plants)

  useEffect(() => {
    setLocalPlants(plants)
  }, [plants])
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) {
      return
    }

    setLocalPlants((plants) => {
      const oldIndex = plants.findIndex((plant) => plant.id === active.id)
      const newIndex = plants.findIndex((plant) => plant.id === over.id)

      const newPlants = arrayMove(plants, oldIndex, newIndex)

      // Update parent_of_order references
      const updatedPlants = newPlants.map((plant, index) => {
        if (index === newPlants.length - 1) {
          return { ...plant, parent_of_order: null }
        }
        return { ...plant, parent_of_order: newPlants[index + 1].id }
      })

      // Notify parent of the update
      onUpdate(updatedPlants)
      return updatedPlants
    })
  }
  const handlePlantUpdate = (updatedPlant: PlantListEntry) => {
    const updatedPlants = localPlants.map((plant) =>
      plant.id === updatedPlant.id ? updatedPlant : plant
    )
    onUpdate(updatedPlants)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Table stickyHeader>
        <TableHead sx={{}}>
          <TableRow>
            <TableCell padding="none" sx={{}} />
            <TableCell padding="checkbox">
              <Checkbox />
            </TableCell>
            <TableCell>
              <Typography>Plant Name</Typography>
            </TableCell>
            <TableCell>
              <Typography>Specs</Typography>
            </TableCell>
            <TableCell>
              <Typography>QTY Needed</Typography>
            </TableCell>
            <TableCell padding="none" sx={{}} />
          </TableRow>
        </TableHead>
        <TableBody>
          <SortableContext
            items={localPlants
              .filter((plant) => !plant.deleted_at)
              .map((plant) => plant.id)}
            strategy={verticalListSortingStrategy}
          >
            {localPlants
              .filter((plant) => !plant.deleted_at)
              .map((plant) => (
                <Fragment key={plant.id}>
                  <RfpPlantTableItem
                    plant={plant}
                    onUpdate={handlePlantUpdate}
                  />
                </Fragment>
              ))}
          </SortableContext>
        </TableBody>
      </Table>
    </DndContext>
  )
}
