import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useEffect, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import {
  Building2Icon,
  FilesIcon,
  FileText,
  PencilIcon,
  Trash2Icon,
} from 'lucide-react'

import { uploadFile } from '@/api/gcs.ts'
import {
  createOrganizationLicense,
  deleteOrganizationLicense,
  updateOrganization,
} from '@/api/organization_management.ts'
import { IMAGE_FILE_TYPES } from '@/constants.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import type {
  GCSFile,
  ImageFile,
  Organization,
  OrganizationLicense,
} from '@/types.ts'
import {
  OrganizationBasicDetailsSchema,
  OrganizationVerificationDetailsSchema,
} from '@/lib/validation-schemas.ts'
import { ZodError, ZodIssue } from 'zod'
import Alert from '@mui/material/Alert'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'

const businessTypeMap: Record<string, string> = {
  buyer: 'Landscaper - Buyer',
  seller: 'Nursery - Seller',
  designer: 'Designer',
  hauler: 'Hauler',
}

type OrganizationDetailsProps = {
  highlightId: boolean
  organization: Organization
  onHighlightAnimationComplete: () => void
}
export default function OrganizationDetails({
  highlightId,
  organization,
  onHighlightAnimationComplete,
}: OrganizationDetailsProps) {
  const queryClient = useQueryClient()
  const { refetchUserInfo } = useUserInfo()
  const [originalOrganizationDetails, setOriginalOrganizationDetails] =
    useState<Organization>(organization)
  const [organizationDetails, setOrganizationDetails] =
    useState<Organization>(organization)
  const [isEditingBasicDetails, setIsEditingBasicDetails] = useState(false)
  const [isEditingVerificationDetails, setIsEditingVerificationDetails] =
    useState(false)
  const [tempLogo, setTempLogo] = useState<string | null>(null)
  const [tempSmallLogo, setTempSmallLogo] = useState<string | null>(null)
  const [validationError, setValidationError] = useState<ZodIssue | null>(null)
  const logoFileInputRef = useRef<HTMLInputElement>(null)
  const smallLogoFileInputRef = useRef<HTMLInputElement>(null)
  const licenseFileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setOriginalOrganizationDetails(organization)
    setOrganizationDetails(organization)
  }, [organization])

  const { data: logo } = useQuery({
    queryKey: ['logo', organizationDetails.logo?.get_url],
    queryFn: async () => {
      if (!organizationDetails.logo?.get_url) {
        return null
      }

      return (await fetchGCSFile(organizationDetails.logo)) as ImageFile
    },
  })
  const { data: smallLogo } = useQuery({
    queryKey: ['small-logo', organizationDetails.small_logo?.get_url],
    queryFn: async () => {
      if (!organizationDetails.small_logo?.get_url) {
        return null
      }
      return (await fetchGCSFile(organizationDetails.small_logo)) as ImageFile
    },
  })

  const updateOrganizationMutation = useMutation({
    mutationFn: updateOrganization,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
      await refetchUserInfo()
    },
  })

  const uploadLogoMutation = useMutation({
    mutationFn: async ({ put_url, file }: { put_url: string; file: File }) => {
      await uploadFile({
        file,
        contentType: file.type,
        putUrl: put_url,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['logo'] })
      await queryClient.invalidateQueries({
        queryKey: ['small-logo'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
    },
  })

  const createOrganizationLicenseMutation = useMutation({
    mutationFn: createOrganizationLicense,
    onSuccess: (file) => {
      setOrganizationDetails({
        ...organizationDetails,
        license: [...(organizationDetails.license || []), file],
      })
    },
  })

  const uploadLicenseMutation = useMutation({
    mutationFn: async ({ put_url, file }: { put_url: string; file: File }) => {
      await uploadFile({
        file,
        contentType: file.type,
        putUrl: put_url,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
    },
  })

  const deleteLicenseMutation = useMutation({
    mutationFn: async (licenseId: string) => {
      await deleteOrganizationLicense(licenseId)
      return licenseId
    },
    onSuccess: async (licenseId) => {
      // Mark the license as deleted
      setOrganizationDetails({
        ...organizationDetails,
        license: organizationDetails.license?.map((license) =>
          license.id === licenseId
            ? { ...license, deleted_at: new Date() }
            : license
        ) as OrganizationLicense[],
      })
      await queryClient.invalidateQueries({
        queryKey: ['selected-organization'],
      })
    },
  })

  const handleCopyID = async () => {
    await navigator.clipboard.writeText(organization.id)
  }

  const handleOrganizationNameChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setOrganizationDetails({
      ...organizationDetails,
      name: event.target.value,
    })
  }

  const clearLogoFileInput = () => {
    if (logoFileInputRef.current) {
      logoFileInputRef.current.value = ''
    }
  }
  const triggerLogoFileInput = () => {
    logoFileInputRef.current?.click()
  }

  const clearSmallLogoFileInput = () => {
    if (smallLogoFileInputRef.current) {
      smallLogoFileInputRef.current.value = ''
    }
  }
  const triggerSmallLogoFileInput = () => {
    smallLogoFileInputRef.current?.click()
  }

  const triggerLicenseFileInput = () => {
    licenseFileInputRef.current?.click()
  }

  const handleLicenseUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    if (file.type !== 'application/pdf') {
      alert('Invalid file type. Please upload a PDF file.')
      return
    }

    const newLicenseFile: Partial<GCSFile> = {
      user_file_name: file.name,
      file_type: file.type,
      encoding: file.type,
      domain: 'license',
    }
    const license = await createOrganizationLicenseMutation.mutateAsync({
      organization: organization.id,
      file: newLicenseFile,
    })

    if (license.file.put_url) {
      await uploadLicenseMutation.mutateAsync({
        file,
        put_url: license.file.put_url,
      })
    }
  }

  const handleDeleteLicense = async (licenseId: string) => {
    await deleteLicenseMutation.mutateAsync(licenseId)
  }

  const handleLogoUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    if (!IMAGE_FILE_TYPES.includes(file.type)) {
      alert('Invalid file type. Please upload an image file.')
      return
    }

    // Verify image
    const image = new Image()
    image.onload = () => {
      // Check image dimensions
      if (image.width !== 230 || image.height !== 130) {
        alert('Image dimensions must be 230 x 130 pixels.')
        clearLogoFileInput()
        return
      }

      // Check image size
      if (file.size > 10 * 1024 * 1024) {
        alert('Image size must be less than 10 MB.')
        clearLogoFileInput()
        return
      }

      // If all checks pass, set the temp logo
      const reader = new FileReader()
      reader.onloadend = () => {
        setTempLogo(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
    image.src = URL.createObjectURL(file)
  }
  const handleRemoveLogo = () => {
    setTempLogo(null)
    setOrganizationDetails({
      ...organizationDetails,
      logo: null,
    })
    clearLogoFileInput()
  }

  const handleSmallLogoUpload = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    if (!IMAGE_FILE_TYPES.includes(file.type)) {
      alert('Invalid file type. Please upload an image file.')
      return
    }

    // Verify image
    const image = new Image()
    image.onload = () => {
      // Check image size
      if (file.size > 10 * 1024 * 1024) {
        alert('Image size must be less than 10 MB.')
        clearLogoFileInput()
        return
      }

      // If all checks pass, set the temp logo
      const reader = new FileReader()
      reader.onloadend = () => {
        setTempSmallLogo(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
    image.src = URL.createObjectURL(file)
  }
  const handleRemoveSmallLogo = () => {
    setTempSmallLogo(null)
    setOrganizationDetails({
      ...organizationDetails,
      small_logo: null,
    })
    clearSmallLogoFileInput()
  }

  const handlePrimaryBusinessTypeChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setOrganizationDetails({
      ...organizationDetails,
      primary_business_type: event.target.value as
        | 'buyer'
        | 'seller'
        | 'designer'
        | 'hauler',
    })
  }

  const handleSave = async (type: string) => {
    const schema =
      type === 'basic'
        ? OrganizationBasicDetailsSchema
        : OrganizationVerificationDetailsSchema

    try {
      await schema.parseAsync(organizationDetails)
      setValidationError(null)
    } catch (error) {
      if (error instanceof ZodError) {
        console.error('validation error:', error.errors[0])
        setValidationError(error.errors[0])
      }
      return // Exit early if validation fails
    }

    const logoFile = logoFileInputRef.current?.files?.[0]
    const smallLogoFile = smallLogoFileInputRef.current?.files?.[0]
    let updatedOrganization: Organization
    if (logoFile) {
      const newLogoFile: Partial<GCSFile> = {
        user_file_name: logoFile.name,
        file_type: logoFile.type,
        encoding: logoFile.type,
        domain: 'logo',
      }
      updatedOrganization = await updateOrganizationMutation.mutateAsync({
        ...organizationDetails,
        logo: newLogoFile,
      })

      if (updatedOrganization.logo?.put_url) {
        await uploadLogoMutation.mutateAsync({
          put_url: updatedOrganization.logo.put_url,
          file: logoFile,
        })
      }
    } else if (smallLogoFile) {
      const newSmallLogoFile: Partial<GCSFile> = {
        user_file_name: smallLogoFile.name,
        file_type: smallLogoFile.type,
        encoding: smallLogoFile.type,
        domain: 'logo',
      }
      updatedOrganization = await updateOrganizationMutation.mutateAsync({
        ...organizationDetails,
        small_logo: newSmallLogoFile,
      })
      if (updatedOrganization.small_logo?.put_url) {
        await uploadLogoMutation.mutateAsync({
          put_url: updatedOrganization.small_logo.put_url,
          file: smallLogoFile,
        })
      }
    } else {
      updatedOrganization =
        await updateOrganizationMutation.mutateAsync(organizationDetails)
    }

    setOriginalOrganizationDetails(updatedOrganization)
    setOrganizationDetails(updatedOrganization)
    setIsEditingBasicDetails(false)
    setIsEditingVerificationDetails(false)
  }

  const resetBasicDetails = () => {
    setValidationError(null)
    setOrganizationDetails({
      ...organizationDetails,
      name: originalOrganizationDetails.name,
      logo: originalOrganizationDetails.logo,
      primary_business_type: originalOrganizationDetails.primary_business_type,
    })
    setTempLogo(null)
    setIsEditingBasicDetails(false)
  }

  const resetVerificationDetails = () => {
    setValidationError(null)
    setOrganizationDetails({
      ...organizationDetails,
      legal_name: originalOrganizationDetails.legal_name,
      tax_id: originalOrganizationDetails.tax_id,
      company_structure: originalOrganizationDetails.company_structure,
    })
    setIsEditingVerificationDetails(false)
  }

  const handleLegalNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrganizationDetails({
      ...organizationDetails,
      legal_name: event.target.value,
    })
  }

  const handleTaxIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrganizationDetails({
      ...organizationDetails,
      tax_id: event.target.value,
    })
  }

  const handleCompanyStructureChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setOrganizationDetails({
      ...organizationDetails,
      company_structure: event.target.value as
        | 'individual'
        | 'llc'
        | 's corp'
        | 'c corp',
    })
  }

  return (
    <>
      {/** Tab Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="tabHeader">Organization Details</Typography>
        <Box display="flex" gap={1} alignItems="center">
          <Typography
            variant="body1"
            className={
              highlightId ? 'animate-[pulse_0.2s_linear_15] text-blue-500' : ''
            }
            onAnimationEnd={() => {
              onHighlightAnimationComplete()
            }}
          >
            Plantbid ID: <span id="organization-id">{organization.id}</span>
          </Typography>
          <IconButton onClick={handleCopyID}>
            <FilesIcon color="grey" size={20} />
          </IconButton>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setIsEditingBasicDetails(true)}
            sx={{
              minWidth: '40px',
              width: '40px',
              height: '40px',
              padding: 0,
              boxShadow: 0,
              border: 1,
              backgroundColor: 'white',
              ':hover': {
                boxShadow: 0,
              },
            }}
          >
            <PencilIcon size={20} />
          </Button>
        </Box>
      </Box>

      {/** Basic Details */}
      <Box display="flex" flexDirection="column" gap={4}>
        {/** Business Name */}
        {isEditingBasicDetails ? (
          <TextField
            label="Business Name"
            value={organizationDetails.name}
            fullWidth
            variant="outlined"
            required
            onChange={handleOrganizationNameChange}
          />
        ) : (
          <Box>
            <Typography variant="tabSection">Business Name</Typography>
            <Typography variant="body1">{organizationDetails.name}</Typography>
          </Box>
        )}

        {/** Primary Business Type */}
        <Box>
          <Typography variant="tabSection">My Primary Business Is:</Typography>
          {isEditingBasicDetails ? (
            <RadioGroup
              defaultValue={organizationDetails.primary_business_type}
              value={organizationDetails.primary_business_type || ''}
              onChange={handlePrimaryBusinessTypeChange}
            >
              <FormControlLabel
                value="buyer"
                control={<Radio />}
                label="Landscaper - Buyer"
              />
              <FormControlLabel
                value="seller"
                control={<Radio />}
                label="Nursery - Seller"
              />
            </RadioGroup>
          ) : (
            <Typography variant="body1">
              {(organizationDetails.primary_business_type &&
                businessTypeMap[organizationDetails.primary_business_type]) ||
                'No Business Type Selected'}
            </Typography>
          )}
        </Box>

        {/** Brand Logo */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="tabSection">Brand Logo</Typography>
            <Typography variant="body1">
              {isEditingBasicDetails
                ? 'Upload a 230 x 130 pixel image, 10 MB max size'
                : organizationDetails.logo?.user_file_name ||
                  'No logo uploaded'}
            </Typography>
            {isEditingBasicDetails && (
              <Box display="flex" gap={2} mt={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={triggerLogoFileInput}
                >
                  <Typography variant="button" textTransform="capitalize">
                    Upload New
                  </Typography>
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  onClick={handleRemoveLogo}
                >
                  <Box display="flex" gap={1}>
                    <Trash2Icon />
                    <Typography variant="button" textTransform="capitalize">
                      Remove
                    </Typography>
                  </Box>
                </Button>
                <input
                  type="file"
                  ref={logoFileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleLogoUpload}
                  accept={IMAGE_FILE_TYPES.join(',')}
                />
              </Box>
            )}
          </Box>
          {tempLogo || logo ? (
            <Box
              component="img"
              sx={{ width: 230, height: 130, boxShadow: 2, borderRadius: 2 }}
              alt="Organization brand logo"
              src={tempLogo || logo?.url}
            />
          ) : (
            <Box
              sx={{ width: 230, height: 130, boxShadow: 2, borderRadius: 2 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Building2Icon size={100} />
            </Box>
          )}
        </Box>

        {/** Small Brand Logo */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="tabSection">Small Brand Logo</Typography>
            <Typography variant="body1">
              {isEditingBasicDetails
                ? 'Upload a square (ideally 48 x 48) pixel image, 10 MB max size'
                : organizationDetails.small_logo?.user_file_name ||
                  'No small logo uploaded'}
            </Typography>
            {isEditingBasicDetails && (
              <Box display="flex" gap={2} mt={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={triggerSmallLogoFileInput}
                >
                  <Typography variant="button" textTransform="capitalize">
                    Upload New
                  </Typography>
                </Button>
                <Button
                  variant="text"
                  color="inherit"
                  onClick={handleRemoveSmallLogo}
                >
                  <Box display="flex" gap={1}>
                    <Trash2Icon />
                    <Typography variant="button" textTransform="capitalize">
                      Remove
                    </Typography>
                  </Box>
                </Button>
                <input
                  type="file"
                  ref={smallLogoFileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleSmallLogoUpload}
                  accept={IMAGE_FILE_TYPES.join(',')}
                />
              </Box>
            )}
          </Box>
          {tempSmallLogo || smallLogo ? (
            <Box
              component="img"
              sx={{ width: 48, height: 48, boxShadow: 2, borderRadius: 2 }}
              alt="Organization brand logo"
              src={tempSmallLogo || smallLogo?.url}
            />
          ) : (
            <Box
              sx={{ width: 48, height: 48, boxShadow: 2, borderRadius: 2 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Building2Icon size={48} />
            </Box>
          )}
        </Box>

        {/** Action Buttons */}
        {validationError &&
        validationError.path.some(
          (p) => p === 'name' || p === 'primary_business_type'
        ) ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {validationError.message}
          </Alert>
        ) : null}
        {isEditingBasicDetails && (
          <Box display="flex" gap={2}>
            <Button variant="contained" onClick={() => handleSave('basic')}>
              <Typography variant="button" textTransform="capitalize">
                Save Changes
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={resetBasicDetails}
            >
              <Typography variant="button" textTransform="capitalize">
                Cancel
              </Typography>
            </Button>
          </Box>
        )}
      </Box>

      <Divider sx={{ my: 6 }} />

      {/** Verification Details */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="tabSection">Verification</Typography>
        <Box display="flex" gap={1} alignItems="center">
          {organizationDetails.verified ? (
            <Chip label="verified" color="success" />
          ) : (
            <Chip label="unverified" color="error" />
          )}
          <Button
            variant="contained"
            color="inherit"
            onClick={() => setIsEditingVerificationDetails(true)}
            sx={{
              minWidth: '40px',
              width: '40px',
              height: '40px',
              padding: 0,
              boxShadow: 0,
              border: 1,
              backgroundColor: 'white',
              ':hover': {
                boxShadow: 0,
              },
            }}
          >
            <PencilIcon size={20} />
          </Button>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gap={4}>
        {/** Legal Business Name */}
        {isEditingVerificationDetails ? (
          <TextField
            label="Legal Business Name"
            value={organizationDetails.legal_name || ''}
            fullWidth
            variant="outlined"
            required
            onChange={handleLegalNameChange}
          />
        ) : (
          <Box>
            <Typography variant="tabSubsection">Legal Business Name</Typography>
            <Typography variant="body1">
              {organizationDetails.legal_name || 'No legal name provided'}
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" gap={2}>
          {/** EIN */}
          {isEditingVerificationDetails ? (
            <TextField
              label="EIN or SSN"
              value={organizationDetails.tax_id || ''}
              fullWidth
              variant="outlined"
              required
              onChange={handleTaxIdChange}
            />
          ) : (
            <Box>
              <Typography variant="tabSubsection">
                EIN - Employer Identification Number
              </Typography>
              <Typography variant="body1">
                {organizationDetails.tax_id || 'No EIN provided'}
              </Typography>
            </Box>
          )}

          {/** Organization Type */}
          {isEditingVerificationDetails ? (
            <TextField
              variant="outlined"
              label="Org Type (optional)"
              fullWidth
              select
              value={organizationDetails.company_structure || ''}
              onChange={handleCompanyStructureChange}
            >
              {['individual', 'llc', 's corp', 'c corp'].map((type) => (
                <MenuItem key={type} value={type}>
                  {type.toUpperCase()}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Box>
              <Typography variant="tabSubsection">Org Type</Typography>
              <Typography variant="body1">
                {organizationDetails.company_structure?.toUpperCase() ||
                  'No org type selected'}
              </Typography>
            </Box>
          )}
        </Box>
        {/** Action Buttons */}
        {validationError &&
        validationError.path.some(
          (p) => p === 'legal_name' || p === 'tax_id'
        ) ? (
          <Alert severity="error" sx={{ mb: 2 }}>
            {validationError.message}
          </Alert>
        ) : null}
        {isEditingVerificationDetails && (
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              onClick={() => handleSave('verification')}
            >
              <Typography variant="button" textTransform="capitalize">
                Save Changes
              </Typography>
            </Button>
            <Button
              variant="contained"
              color="inherit"
              onClick={resetVerificationDetails}
            >
              <Typography variant="button" textTransform="capitalize">
                Cancel
              </Typography>
            </Button>
          </Box>
        )}
      </Box>

      <Divider sx={{ my: 6 }} />

      {/** Licenses */}
      <Typography variant="tabSection" mb={2}>
        Licenses
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        {organizationDetails.license?.filter((license) => !license.deleted_at)
          ?.length ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {organizationDetails.license
              .filter((license) => !license.deleted_at)
              .map((license) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  key={license.id}
                >
                  <Box display="flex" gap={1}>
                    <FileText />
                    <Typography variant="body1">
                      {license.file.user_file_name}
                    </Typography>
                  </Box>
                  <Button
                    variant="text"
                    color="inherit"
                    onClick={() => handleDeleteLicense(license.id)}
                  >
                    <Typography variant="button" textTransform="capitalize">
                      Remove
                    </Typography>
                  </Button>
                </Box>
              ))}
          </Box>
        ) : (
          <Typography variant="body1">No licenses uploaded</Typography>
        )}
        <Button
          variant="outlined"
          color="inherit"
          onClick={triggerLicenseFileInput}
          sx={{ width: 'fit-content' }}
        >
          <Typography variant="button" textTransform="capitalize">
            Upload License
          </Typography>
        </Button>
        <input
          type="file"
          ref={licenseFileInputRef}
          style={{ display: 'none' }}
          onChange={handleLicenseUpload}
          accept={'application/pdf'}
        />
      </Box>
    </>
  )
}
