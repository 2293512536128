import { ReactNode, useState } from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { Badge, Stack } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import IconButton from '@mui/material/IconButton'
import { KeyboardDoubleArrowRight } from '@mui/icons-material'

interface DrawerMiniRightProps {
  tabs: {
    label: string
    icon: ReactNode
    count: number
    content: ReactNode
  }[]
}

export const DrawerMiniRight = ({ tabs }: DrawerMiniRightProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const DrawerMinimized = () => {
    return (
      <Stack spacing={4} sx={{ py: 2.5, px: 2 }} justifyContent="center">
        <IconButton
          key="keyCloseDrawer"
          onClick={() => setIsOpen(true)}
          sx={{ width: 40, height: 40 }}
        >
          <KeyboardDoubleArrowRight />
        </IconButton>
        {tabs.map((tab) => (
          <Box
            key={tab.label}
            sx={{
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <Badge
              badgeContent={tab.count}
              color="primary"
              sx={{
                '& .MuiBadge-badge': {
                  borderRadius: 1,
                  top: -1,
                  right: -1,
                },
              }}
            >
              {tab.icon}
            </Badge>
          </Box>
        ))}
      </Stack>
    )
  }

  const DrawerExpanded = () => {
    const ExpandedTabButton = ({
      name,
      count,
    }: {
      name: string
      count: number
    }) => {
      return (
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>{name}</Box>
          {count > 0 && (
            <Badge
              badgeContent={count}
              color="primary"
              sx={{
                '& .MuiBadge-badge': {
                  height: 'auto',
                  padding: '0.25rem',
                  borderRadius: 1,
                },
              }}
            />
          )}
        </Stack>
      )
    }

    return (
      <Box sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center">
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{ flex: 1 }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.label}
                label={<ExpandedTabButton name={tab.label} count={tab.count} />}
                sx={{
                  fontWeight: activeTab === tabs.indexOf(tab) ? 700 : 400,
                }}
              />
            ))}
          </Tabs>
          <IconButton onClick={() => setIsOpen(false)} sx={{ ml: 4 }}>
            <KeyboardDoubleArrowRight />
          </IconButton>
        </Stack>
        <Box>{tabs[activeTab].content}</Box>
      </Box>
    )
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={isOpen}
      sx={{
        paddingTop: '1px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          position: 'relative',
          transition: 'width 0.2s ease',
          overflowX: 'hidden',
        },
      }}
    >
      {isOpen ? <DrawerExpanded /> : <DrawerMinimized />}
    </Drawer>
  )
}
