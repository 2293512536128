import { PlantList } from '@/types.ts'
import api from '@/api/api.ts'
import { uploadFile } from '@/api/gcs.ts'
import { processFileWithAI } from '@/api/ai-processed-plant-list-file.ts'

type PlantListRequest = {
  name: string
  organization: string
  files: File[]
}
export async function createPlantList(plantListRequest: PlantListRequest) {
  const files = plantListRequest.files.map((file) => ({
    user_file_name: file.name,
    encoding: file.type,
    domain: 'plant_list',
    file_type: file.type,
  }))
  const body = {
    name: plantListRequest.name,
    files,
    organization: plantListRequest.organization,
  }

  const response = await api.post('/v1/mdb/plant_list/', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const newPlantList = response.data as PlantList

  if (response.status === 201 && newPlantList.files.length > 0) {
    // upload plant list files to cloud storage
    for (const file of newPlantList.files) {
      const formFiles = plantListRequest.files
      const fileToUpload = formFiles.find((f) => f.name === file.user_file_name)
      if (!fileToUpload) {
        throw new Error(
          `File ${file.user_file_name} not found in the form files`
        )
      }
      const putUrl = file.put_url
      const contentType = file.file_type
      await uploadFile({ file: fileToUpload, contentType, putUrl })
    }

    // process each file with AI
    for (const file of newPlantList.files) {
      await processFileWithAI({
        organizationId: plantListRequest.organization,
        fileId: file.id,
        plantListId: newPlantList.id,
      })
    }
  }

  return newPlantList
}

export async function getPlantListsByOrganization(
  page: number,
  limit: number,
  organization_id: string
) {
  const response = await api.get(
    `/v1/mdb/plant_list/?limit=${limit}&page=${page}&organization_id=${organization_id}&order_by=created_at&order_by_direction=desc`
  )

  return response.data as PlantList[]
}
