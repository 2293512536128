import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import RfpPlantTable from '@/components/rfps/rfp-plant-table.tsx'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { PlantListEntry, PlantWithQuantity } from '@/types.ts'
import { MouseEvent, useState } from 'react'
import { generateObjectId } from '@/lib/utils.ts'
import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants.ts'
import AddPlantsModal from '@/components/ui/modals/add-plants-modal.tsx'
import { RFPPlantsAndActionsProps } from '@/components/rfps/types.ts'
import { Stack } from '@mui/material'
import { Add, KeyboardArrowDown } from '@mui/icons-material'
import { AbstractDetailsComponentDivider } from '@/components/rfps/abstract-details-card.tsx'

export const RFPPlantsAndActions = ({
  plants,
  onUpdateRfpData,
  rfpData,
}: RFPPlantsAndActionsProps) => {
  const handlePlantsUpdate = (updatedPlants: PlantListEntry[]) => {
    onUpdateRfpData({ plants: updatedPlants })
  }
  const [addPlantsAnchorEl, setAddPlantsAnchorEl] =
    useState<null | HTMLElement>(null)
  const [bulkActionsAnchorEl, setBulkActionsAnchorEl] =
    useState<null | HTMLElement>(null)
  const addPlantsOpen = Boolean(addPlantsAnchorEl)
  const bulkActionsOpen = Boolean(bulkActionsAnchorEl)
  const [addPlantsModalOpen, setAddPlantsModalOpen] = useState(false)

  const handleAddPlantsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAddPlantsAnchorEl(event.currentTarget)
  }

  const handleBulkActionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setBulkActionsAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAddPlantsAnchorEl(null)
    setBulkActionsAnchorEl(null)
  }

  const BulkActionsMenuItem = () => {
    const handleClick = () => {
      handleClose()
    }

    return (
      <Box>
        <Menu
          anchorEl={bulkActionsAnchorEl}
          open={bulkActionsOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClick}>Menu Item 1</MenuItem>
          <Divider />
          <MenuItem onClick={handleClick}>Menu Item 2</MenuItem>
        </Menu>
      </Box>
    )
  }

  const handleAddPlants = async (plants: PlantWithQuantity[]) => {
    const updatedPlantEntries = [...(rfpData?.plants || [])]

    for (const plant of plants) {
      const newId = generateObjectId()
      const newPlant: PlantListEntry = {
        ...DEFAULT_PLANT_LIST_ENTRY,
        id: newId,
        is_new: true,
        quantity_count: { min: plant.quantity, max: null },
        scientific_name: plant.plant.scientific_name,
        common_name: plant.plant.common_names[0],
        parent_of_order: null,
      }

      const currentLastEntry = updatedPlantEntries.find(
        (entry) => entry.parent_of_order === null
      )
      if (currentLastEntry) {
        currentLastEntry.parent_of_order = newId
      }
      updatedPlantEntries.push(newPlant)
    }

    setAddPlantsModalOpen(false)
    handleClose()
    onUpdateRfpData({ plants: updatedPlantEntries })
  }

  const AddPlantMenuItem = () => {
    const handleAddPlantsManually = () => {
      setAddPlantsModalOpen(true)
      handleClose()
    }

    return (
      <Box>
        <Menu
          anchorEl={addPlantsAnchorEl}
          open={addPlantsOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleAddPlantsManually}>Add Manually</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>From File Upload...</MenuItem>
        </Menu>
        <AddPlantsModal
          open={addPlantsModalOpen}
          onClose={() => setAddPlantsModalOpen(false)}
          onAddPlants={handleAddPlants}
        />
      </Box>
    )
  }

  const RequestPricingHeader = () => {
    const defaultTitle = 'Request Pricing for Plants'
    const title =
      plants.length > 0
        ? `Request Pricing for ${plants.length} Plants (x Items)`
        : defaultTitle

    return (
      <Typography variant="body1" sx={{ fontWeight: 700, pl: 2 }}>
        {title}
      </Typography>
    )
  }

  return (
    <Stack>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', mb: 1, alignItems: 'center' }}
      >
        <RequestPricingHeader />

        <Stack direction="row" sx={{ gap: 1 }}>
          {/* bulk actions */}
          <Button
            variant="outlined"
            onClick={handleBulkActionsClick}
            endIcon={<KeyboardArrowDown fontSize="small" />}
          >
            <Typography variant="button">Bulk Actions</Typography>
          </Button>
          <BulkActionsMenuItem />

          {/* add plants */}
          <Button
            variant="contained"
            onClick={handleAddPlantsClick}
            startIcon={<Add fontSize="small" />}
          >
            <Typography variant="button">Add Plants</Typography>
          </Button>
          <AddPlantMenuItem />
        </Stack>
      </Stack>

      <AbstractDetailsComponentDivider />

      <RfpPlantTable plants={plants} onUpdate={handlePlantsUpdate} />
    </Stack>
  )
}
