import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import { Fragment } from 'react'
import {
  AbstractDetailsComponentProps,
  AbstractDetailsContentProps,
} from '@/components/rfps/types.ts'

export const AbstractDetailsTextContent = ({
  text,
  bold,
}: {
  text: string
  bold?: boolean
}) => {
  const fontWeight = bold ? 700 : 400
  return (
    <Typography variant="body2" fontWeight={fontWeight}>
      {text}
    </Typography>
  )
}

export const AbstractDetailsComponentDivider = () => {
  return <Divider sx={{ border: '2px solid #7F96A7' }} />
}

export const AbstractDetailsComponent = (
  details: AbstractDetailsComponentProps
) => {
  return (
    <Stack sx={{ width: '100%' }} key={details.name}>
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          {details.name}
        </Typography>
      </Box>
      <AbstractDetailsComponentDivider />
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1} columns={3}>
          {details?.content
            ? details.content.map(
                (detail: AbstractDetailsContentProps, index: number) => {
                  return (
                    <Fragment key={index}>
                      <Grid size={1}>
                        <AbstractDetailsTextContent
                          text={`${detail.name}:`}
                          bold={true}
                        />
                      </Grid>
                      <Grid size={2}>{detail.content}</Grid>
                    </Fragment>
                  )
                }
              )
            : null}
        </Grid>
      </Box>
    </Stack>
  )
}
