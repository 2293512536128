import { useAuth0 } from '@auth0/auth0-react'
import { RouterProvider } from '@tanstack/react-router'
import { Suspense, useEffect } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

import { OrganizationProvider } from '@/contexts/OrganizationContext'
import { UserInfoProvider } from '@/contexts/UserInfoContext'
import { initializeApi, refreshLogin } from './api/api'
import router from './router'
import { PubNubProvider } from './contexts/PubNubContext'
import { ToastNotificationsProvider } from '@/contexts/ToastNotificationContext.tsx'

function Loader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap={1}
      height="100vh"
    >
      <CircularProgress size={18} />
      <Typography>Loading...</Typography>
    </Box>
  )
}

function AppContent() {
  const auth = useAuth0()

  useEffect(() => {
    initializeApi(auth.getAccessTokenSilently)
    refreshLogin(() =>
      auth.loginWithRedirect({ appState: { returnTo: window.location.href } })
    )
  }, [auth])

  return (
    <ToastNotificationsProvider>
      <UserInfoProvider>
        <OrganizationProvider>
          <PubNubProvider>
            <RouterProvider router={router} context={auth} />
          </PubNubProvider>
        </OrganizationProvider>
      </UserInfoProvider>
    </ToastNotificationsProvider>
  )
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AppContent />
    </Suspense>
  )
}
