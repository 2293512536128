import { Fragment, HTMLAttributes, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import { Plus, Trash2 } from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import { basicSearchV1Plant } from '@/api/elasticsearch.ts'
import theme from '@/theme.ts'
import { ElasticsearchResponse, Plant, PlantWithQuantity } from '@/types.ts'
import {
  DarkPrimaryButton,
  PrimaryCancelButton,
} from '@/components/ui/base/buttons/buttons.tsx'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { Stack } from '@mui/material'
import Divider from '@mui/material/Divider'

interface AddPlantsModalProps {
  open: boolean
  onClose: () => void
  onAddPlants: (plants: PlantWithQuantity[]) => Promise<void>
}

export default function AddPlantsModal({
  open,
  onClose,
  onAddPlants,
}: AddPlantsModalProps) {
  const [selectedPlants, setSelectedPlants] = useState<PlantWithQuantity[]>([])

  const handleClose = () => {
    setSelectedPlants([])
    onClose()
  }

  const removeSelectedPlant = (plant: PlantWithQuantity) => {
    setSelectedPlants((prev) =>
      prev.filter((p) => p.plant._id !== plant.plant._id)
    )
  }

  const updatePlantQuantity = (
    plant: PlantWithQuantity,
    newQuantity: number
  ) => {
    setSelectedPlants((prev) =>
      prev.map((p) =>
        p.plant._id === plant.plant._id ? { ...p, quantity: newQuantity } : p
      )
    )
  }

  const handleAddPlants = async () => {
    await onAddPlants(selectedPlants)
    setSelectedPlants([])
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      aria-labelledby="add-plants-modal-title"
      aria-describedby="add-plants-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader
          title="Add Plants"
          sx={{ mb: 5 }}
          id="add-plants-modal-title"
        />
        <Stack spacing={2} sx={{ mb: selectedPlants.length > 0 ? 5 : 0 }}>
          {selectedPlants.map((plant, index) => (
            <Fragment key={plant.plant._id}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Typography variant="body1">
                      {plant.plant.common_names[0]}
                    </Typography>
                    <Typography variant="body1">
                      ({plant.plant.scientific_name})
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ justifyContent: 'flex-end' }}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Typography variant="strong">QTY</Typography>
                    <TextField
                      type="number"
                      value={plant.quantity}
                      onChange={(e) => {
                        const value = parseInt(e.target.value) || 1
                        updatePlantQuantity(plant, Math.max(1, value))
                      }}
                      inputProps={{ min: 1 }}
                    />
                    <Box
                      sx={{ ':hover': { cursor: 'pointer' } }}
                      onClick={() => removeSelectedPlant(plant)}
                    >
                      <Trash2 size={22} strokeWidth={2.5} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {index < selectedPlants.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Stack>
        <Box>
          <V2PlantAutocompleteSearch
            onChange={(value) => {
              if (value) {
                setSelectedPlants((prev) => [
                  ...prev,
                  { plant: value, quantity: 1 },
                ])
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 5,
          }}
        >
          <PrimaryCancelButton onClick={handleClose}>
            Cancel &amp; Close
          </PrimaryCancelButton>
          <DarkPrimaryButton
            onClick={handleAddPlants}
            disabled={!selectedPlants.length}
          >
            Add Plants
          </DarkPrimaryButton>
        </Box>
      </Box>
    </Modal>
  )
}

type RenderOptionProps = HTMLAttributes<HTMLLIElement> & {
  key?: string
  'data-option-index'?: number
}

type V2PlantAutocompleteSearchProps = {
  onChange: (value: Plant | null) => void
}

function V2PlantAutocompleteSearch({
  onChange,
}: V2PlantAutocompleteSearchProps) {
  const [inputValue, setInputValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [open, setOpen] = useState(false)

  const { data: searchResults, isFetching: isSearching } = useQuery({
    queryKey: ['v2-plant-search', searchTerm],
    queryFn: () =>
      basicSearchV1Plant(searchTerm) as Promise<ElasticsearchResponse<Plant>>,
    enabled: searchTerm.length > 2,
  })

  const handleClose = () => {
    setOpen(false)
    setInputValue('')
    setSearchTerm('')
  }

  return (
    <Autocomplete
      open={searchTerm.length > 2 && open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      options={searchResults?.results || []}
      getOptionLabel={(option) =>
        `${option.common_names[0] || 'N/A'} (${option.scientific_name})`
      }
      sx={{ minWidth: 300 }}
      filterOptions={(options) => options}
      renderOption={(props: RenderOptionProps, option) => {
        const { key, ...optionsProps } = props
        return (
          <Box key={key} component="li" {...optionsProps}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, py: 2 }}>
              <Box
                sx={{
                  width: 24,
                  height: 24,
                  bgcolor: theme.palette.black.main,
                  borderRadius: theme.borderRadius.circle,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Plus size={18} color="white" />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Typography variant="body1" textTransform="capitalize">
                  {option.common_names[0] || 'N/A'}
                </Typography>
                <Typography variant="body1" textTransform="capitalize">
                  ({option.scientific_name})
                </Typography>
              </Box>
            </Box>
          </Box>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for a plant..."
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {isSearching && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      loading={isSearching}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'input') {
          setInputValue(newInputValue)
          setSearchTerm(newInputValue)
        } else {
          // clear input
          setInputValue('')
        }
      }}
      onChange={(_event, newValue) => {
        onChange(newValue)
        // Close the dropdown after selecting a plant
        handleClose()
      }}
      value={null}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option._id === value?._id}
      clearOnBlur={false}
      selectOnFocus={true}
      disableCloseOnSelect
    />
  )
}
