import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { RfpOrganizationDetails } from '@/components/rfps/rfp-organization-details.tsx'
import { Stack } from '@mui/material'
import { RFPDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-rfp-details.tsx'
import {
  JobDetails,
  NotVisibleToVendors,
} from '@/components/rfps/draft/steps/build-rfp-step-content-job-details.tsx'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import type { RFPStepProps } from '@/components/rfps/types.ts'
import { RFPPlantsAndActions } from '@/components/rfps/draft/steps/build-rfp-step-content-plants.tsx'
import { ChangeEvent, useState } from 'react'
import TextField from '@mui/material/TextField'
import { AbstractDetailsComponentDivider } from '@/components/rfps/abstract-details-card.tsx'

export const RFPPageContent = ({ rfpData, onUpdateRfpData }: RFPStepProps) => {
  const { selectedOrganization } = useOrganization()

  if (!rfpData) {
    return null
  }

  const plants = rfpData?.plants || []

  const RFPVendorComments = () => {
    const [rfpVendorComments, setRfpVendorComments] = useState('')

    const handleInputChange = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setRfpVendorComments(e.target.value)
    }

    return (
      <Stack sx={{ width: '100%', gap: 2 }}>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700, pl: 2 }}>
            General Comments (for Vendors)
          </Typography>
        </Stack>
        <AbstractDetailsComponentDivider />
        <TextField
          variant="outlined"
          name="vendorComments"
          placeholder="Type comments here ..."
          fullWidth
          multiline
          rows={5}
          value={rfpVendorComments}
          onChange={(e) => handleInputChange(e)}
        />
      </Stack>
    )
  }

  const RFPInternalNotes = () => {
    const [rfpInternalNotes, setRfpInternalNotes] = useState('')
    const handleInputChange = (
      e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      setRfpInternalNotes(e.target.value)
    }
    return (
      <Stack sx={{ width: '100%', gap: 2 }}>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="body1" sx={{ fontWeight: 700, pl: 2 }}>
            General Notes
          </Typography>
          <NotVisibleToVendors />
        </Stack>
        <AbstractDetailsComponentDivider />
        <TextField
          variant="outlined"
          name="generalNotes"
          placeholder="Type notes here ..."
          fullWidth
          multiline
          rows={5}
          value={rfpInternalNotes}
          onChange={(e) => handleInputChange(e)}
        />
      </Stack>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack
        sx={{
          overflow: 'hidden',
          borderRadius: theme.borderRadius.lg,
          bgcolor: 'white',
          p: 5.5,
          gap: 3,
        }}
      >
        <RfpOrganizationDetails organization={selectedOrganization} />
        <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2 }}>
          <RFPDetails rfpData={rfpData} />
          <JobDetails rfpData={rfpData} />
        </Stack>
        <RFPPlantsAndActions
          plants={plants}
          rfpData={rfpData}
          onUpdateRfpData={onUpdateRfpData}
        />
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', gap: 2, mt: 3 }}
        >
          <RFPVendorComments />
          <RFPInternalNotes />
        </Stack>
      </Stack>
    </Box>
  )
}
