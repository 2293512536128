import { useEffect, useState } from 'react'
import type { ImageFile } from '@/types.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import Avatar from '@mui/material/Avatar'
import { Building2Icon } from 'lucide-react'

export const RFPOrganizationLogo = ({
  logo,
  size,
}: {
  logo: any
  size: number
}) => {
  const [logoImage, setLogoImage] = useState<ImageFile | null>(null)

  useEffect(() => {
    const fetchLogo = async () => {
      if (logo) {
        const image = await fetchGCSFile(logo)
        setLogoImage(image as ImageFile)
      } else {
        setLogoImage(null)
      }
    }

    fetchLogo()
  }, [logo])

  return logoImage ? (
    <Avatar
      src={logoImage.url}
      alt="organization-logo"
      variant="rounded"
      sx={{
        height: size,
        width: size,
      }}
    />
  ) : (
    <Avatar
      alt="organization-logo"
      variant="rounded"
      sx={{
        height: size,
        width: size,
      }}
    >
      <Building2Icon />
    </Avatar>
  )
}
