import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { Files } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import { ElasticsearchResponse } from '@/types'

export const Route = createFileRoute('/_authenticated/quotes/')({
  component: QuotesIndex,
})

// Example Quote type
type Quote = {
  id: string
  name: string
}

function QuotesIndex() {
  const [activeTab, setActiveTab] = useState('by-customer')
  const [_searchResults, setSearchResults] =
    useState<ElasticsearchResponse<Quote> | null>(null)

  return (
    <PageLayout title="Quotes" icon={<Files size={36} />} globalNewButton>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'By Customer', value: 'by-customer' },
            { label: 'Plant List', value: 'by-plant-list' },
          ],
          activeTab: activeTab,
          onTabChange: setActiveTab,
        }}
        searchConfig={{
          placeholder: 'Search Quotes...',
          searchUrl: '/v1/core/quotes/search/',
          onSearchResults: (response) =>
            setSearchResults(response as ElasticsearchResponse<any>),
        }}
      />
    </PageLayout>
  )
}
