import { ConfirmationModal } from '@/components/ui/modals/confirmation-modal.tsx'

type PlantListVerificationModalProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}
const PlantListVerificationModal = ({
  open,
  onClose,
  onConfirm,
}: PlantListVerificationModalProps) => {
  const onConfirmModal = () => {
    onClose()
    onConfirm()
  }

  return (
    <ConfirmationModal
      key="verify-plants-modal"
      open={open}
      title="PlantList Verification"
      message="By confirming, you acknowledge and agree that all information on this list is accurate and complete to the best of your knowledge."
      onCancel={onClose}
      onConfirm={onConfirmModal}
    />
  )
}
export default PlantListVerificationModal
