import {
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Typography,
} from '@mui/material'
import { PopoverProps } from '@mui/material/Popover'
import { SvgIconComponent } from '@mui/icons-material'
import Box from '@mui/material/Box'

interface MenuItemType {
  label: string
  onClick: () => void
  icon?: SvgIconComponent
  divider?: boolean
  category?: string
}

interface CustomMenuProps {
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
  width?: number | string
  anchorOrigin?: PopoverProps['anchorOrigin']
  transformOrigin?: PopoverProps['transformOrigin']
  menuItems: MenuItemType[]
}

export const CustomMenu = ({
  anchorEl,
  open,
  onClose,
  width = 200,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  menuItems,
}: CustomMenuProps) => {
  // Group items by category
  const categorizedItems = menuItems.reduce(
    (acc, item) => {
      const category = item.category || 'uncategorized'
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push(item)
      return acc
    },
    {} as Record<string, MenuItemType[]>
  )

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      slotProps={{
        paper: {
          sx: {
            width: width,
          },
        },
      }}
    >
      {Object.entries(categorizedItems).map(
        ([category, items], categoryIndex) => (
          <Box key={`category-${category}-${categoryIndex}`}>
            {/* Only show category header if it's not uncategorized */}
            {category !== 'uncategorized' && (
              <Typography
                sx={{
                  px: 2,
                  py: 0.5,
                  display: 'block',
                  color: 'text.primary',
                  fontWeight: 500,
                }}
              >
                {category}
              </Typography>
            )}

            {items.map((item, index) => (
              <Box key={index}>
                <MenuItem onClick={item.onClick}>
                  {item.icon && (
                    <ListItemIcon>
                      <item.icon fontSize="small" />
                    </ListItemIcon>
                  )}
                  {item.label}
                </MenuItem>
                {item.divider && <Divider />}
              </Box>
            ))}
          </Box>
        )
      )}
    </Menu>
  )
}
