import { type HTMLAttributes, type ReactNode } from 'react'
import { type SxProps, type Theme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import { X } from 'lucide-react'

interface ModalHeaderProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  variant?: 'modalHeader1' | 'modalHeader2'
  icon?: ReactNode
  showCloseButton?: true
  onCloseButtonClick?: () => void
  sx?: SxProps<Theme>
}
export default function ModalHeader({
  title,
  variant = 'modalHeader1',
  icon,
  showCloseButton,
  onCloseButtonClick,
  sx,
  ...props
}: ModalHeaderProps) {
  const { id, ...rest } = props
  return (
    <Box
      {...rest}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={sx}
    >
      <Box display="flex" alignItems="center" gap={1}>
        {icon}
        <Typography variant={variant} id={id}>
          {title}
        </Typography>
      </Box>
      {showCloseButton && onCloseButtonClick && (
        <IconButton onClick={onCloseButtonClick}>
          <X size={24} />
        </IconButton>
      )}
    </Box>
  )
}
