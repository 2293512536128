import { useMutation } from '@tanstack/react-query'
import { updateUser } from '@/api/user.ts'
import React, { useState } from 'react'
import { User } from '@/types.ts'
import { Box, Stack, Typography } from '@mui/material'
import review_accuracy_modal_illustration from '@/assets/review_accuracy_modal_illustration.png'
import { InfoOutlined } from '@mui/icons-material'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'

const ReviewAccuracyModal = () => {
  const { userInfo } = useUserInfo()
  const [reviewAccuracyModalOpen, setReviewAccuracyModalOpen] = useState(
    userInfo?.user?.dont_show?.plantlist_detail_review === null
  )

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
  })

  const handleAckowledgement = async (
    _: React.SyntheticEvent,
    checked: boolean
  ) => {
    // set value based on checkbox state
    const value = checked ? new Date().toISOString() : null

    // create object with the dont_show patch data
    const dontShowPatchData = {
      id: userInfo?.user?.id,
      dont_show: {
        ...userInfo?.user?.dont_show,
        plantlist_detail_review: value,
      },
    } as User

    // call the updateUserMutation to update the user
    await updateUserMutation.mutateAsync(dontShowPatchData)
  }

  const handleClose = async () => {
    setReviewAccuracyModalOpen(false)
  }

  return reviewAccuracyModalOpen ? (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          width: '60%',
          backgroundColor: 'white',
          borderRadius: '1em',
          display: 'flex',
          flexDirection: 'column',
          blur: '5px',
          opacity: 1,
          boxShadow: '0 0 10px rgba(0,0,0,0.5)',
        }}
      >
        <Box
          component="img"
          src={review_accuracy_modal_illustration}
          alt="review_accuracy_modal_illustration"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
            borderRadius: '1em 1em 0 0',
          }}
        />
        <Stack
          direction="column"
          sx={{ paddingX: '1em', paddingY: '1.5em', gap: '1em' }}
        >
          <Stack
            direction="row"
            sx={{
              gap: '0.5em',
            }}
          >
            <InfoOutlined />
            <Typography variant="strong">Please review for accuracy</Typography>
          </Stack>
          <Stack sx={{ gap: '1em' }}>
            <Typography variant="body1">
              The Plant List uploader makes mistakes from time to time.
            </Typography>
            <Typography variant="body1">
              Verify the plants, quantities and specs on this list match my
              uploaded files. Click on a plant to make edits.
            </Typography>
          </Stack>
          <Stack>
            <DarkPrimaryButton
              onClick={handleClose}
              sx={{ width: 'fit-content' }}
            >
              View Plant List
            </DarkPrimaryButton>
            <FormControlLabel
              onChange={handleAckowledgement}
              control={<Checkbox />}
              label={
                <Typography variant="strong">
                  I get it. Don&apos;t show this message again
                </Typography>
              }
              sx={{ mt: 1 }}
            />
          </Stack>
        </Stack>
      </Box>
    </Box>
  ) : null
}
export default ReviewAccuracyModal
