import { Organization } from '@/types.ts'
import React, {
  createContext,
  useState,
  type ReactNode,
  useEffect,
} from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  useQuery,
} from '@tanstack/react-query'
import { getOrganizationById } from '@/api/organization_management.ts'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'

type OrganizationContextType = {
  selectedOrganizationId: string | undefined
  setSelectedOrganizationId: (id: string) => void
  selectedOrganization: Organization | null | undefined
  organizationModalOpen: boolean
  toggleOrganizationModal: (value: boolean | null) => void
  refetchSelectedOrganization: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Organization, Error>>
}

export const OrganizationContext =
  createContext<OrganizationContextType | null>(null)

type OrganizationProviderProps = {
  children: ReactNode
}
export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
}) => {
  const { userOrganizations } = useUserInfo()
  const [organizationModalOpen, setOrganizationModalOpen] = useState(false)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>()

  const { data: selectedOrganization, refetch: refetchSelectedOrganization } =
    useQuery({
      queryKey: ['selected-organization', selectedOrganizationId],
      queryFn: () => {
        return getOrganizationById(selectedOrganizationId)
      },
      enabled: !!selectedOrganizationId,
    })

  useEffect(() => {
    if (userOrganizations.length > 0 && !selectedOrganizationId) {
      setSelectedOrganizationId(userOrganizations[0].id)
    }
  }, [selectedOrganizationId, userOrganizations])

  const toggleOrganizationModal = (value: boolean | null) => {
    value === null
      ? setOrganizationModalOpen((prev) => !prev)
      : setOrganizationModalOpen(value)
  }

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization,
        selectedOrganizationId,
        setSelectedOrganizationId,
        organizationModalOpen,
        toggleOrganizationModal,
        refetchSelectedOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
