import {
  type QueryObserverResult,
  type RefetchOptions,
  useQuery,
} from '@tanstack/react-query'
import React, { createContext, type ReactNode, useMemo } from 'react'

import { Organization, UserInfo } from '@/types.ts'
import { getUserInfo } from '@/api/user.ts'
import { useAuth0 } from '@auth0/auth0-react'

type UserInfoContextType = {
  userInfo: UserInfo | undefined
  userOrganizations: Organization[]
  refetchUserInfo: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserInfo, Error>>
}
export const UserInfoContext = createContext<UserInfoContextType | undefined>(
  undefined
)

interface UserInfoProviderProps {
  children: ReactNode
}
export const UserInfoProvider: React.FC<UserInfoProviderProps> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth0()

  const { data: userInfo, refetch: refetchUserInfo } = useQuery({
    queryKey: ['user-info'],
    queryFn: getUserInfo,
    enabled: isAuthenticated,
  })

  const userOrganizations = useMemo(
    () => userInfo?.user?.organization_members?.map((member) => member.organization) ?? [],
    [userInfo]
  )

  return (
    <UserInfoContext.Provider
      value={{ userInfo, userOrganizations, refetchUserInfo }}
    >
      {children}
    </UserInfoContext.Provider>
  )
}
